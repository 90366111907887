import ResourceApi, { Resource, SearchParams } from 'api/ResourceAPI';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import DeliverableSheetWorkunit from 'types/entities/DeliverableSheetWorkunit';
import Order from 'types/entities/Order';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import { ReportStatus } from '../types/enums/ReportStatus';

export default class DeliverableSheetApiService {
  public static instance: DeliverableSheetApiService;

  public static baseUrl = 'deliverable-sheets' as Resource;

  public static reportTechnicalUrl = 'reports/technical' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return (
      DeliverableSheetApiService.instance || (DeliverableSheetApiService.instance = new DeliverableSheetApiService())
    );
  }

  public static async createTechnicalReport(
    order_id: Order['id'],
    body: {
      title?: string;
      order_workunit_ids?: OrderWorkunit['id'][];
      scope_ids?: Scope['id'][];
      start_date?: string;
      end_date?: string;
      all_production_clients_ids?: number[];
      assigned_production_clients_ids?: number[];
      type: 'technical' | 'financial';
      filters?: {
        select_all: boolean;
      };
    }
  ): Promise<DeliverableSheet[]> {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await ResourceApi.post(this.baseUrl, {
        ...body,
        order_id,
      });
      return data.deliverable_sheet;
    } catch (error: unknown) {
      throw Error('Error while generating technical report');
    }
  }

  public static async generateFinancialReport(
    order_id: Order['id'],
    body: {
      title?: string;
      order_workunit_ids?: OrderWorkunit['id'][];
      scope_ids?: Scope['id'][];
      start_date?: string;
      end_date?: string;
      all_production_clients_ids?: number[];
      assigned_production_clients_ids?: number[];
    }
  ): Promise<DeliverableSheet[]> {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await ResourceApi.post(`orders/${order_id}/financial-reports` as Resource, {
        ...body,
        order_id,
      });
      return data.deliverable_sheet;
    } catch (error: unknown) {
      throw Error('Error while generating financial report');
    }
  }

  public static async update(
    deliverableSheet: DeliverableSheet | (Pick<DeliverableSheet, 'id'> & Partial<DeliverableSheet>)
  ) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await ResourceApi.patchById(this.baseUrl, deliverableSheet.id, deliverableSheet);
      return data;
    } catch (error: unknown) {
      throw error;
    }
  }

  public static async archive(deliverableSheetId: DeliverableSheet['id']) {
    return ResourceApi.delete(this.baseUrl, deliverableSheetId);
  }

  public static async fetchDeliverableSheetPdf(deliverableSheetId: number) {
    try {
      const data = await ResourceApi.getById(this.reportTechnicalUrl, deliverableSheetId, `pdf`);
      return data.data;
    } catch (error) {
      return undefined;
    }
  }

  public static async triggerTechnicalReportGeneration(deliverableSheetId: number) {
    try {
      const {
        data: { deliverable_sheet_pdf_presigned_uri: deliverableSheetPdfUri },
      }: { data: { deliverable_sheet_pdf_presigned_uri: string } } = await ResourceApi.getById(
        this.baseUrl,
        deliverableSheetId,
        `pdf`
      );

      return deliverableSheetPdfUri;
    } catch (error) {
      return undefined;
    }
  }

  public static async isPdfAlreadyGenerated(url: string) {
    try {
      const response = await fetch(url);
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  public static async getUrlToUploadSignature(deliverableSheetId: number) {
    try {
      const data = await ResourceApi.getById(this.baseUrl, deliverableSheetId, `signature`);
      return data.data;
    } catch (error) {
      return undefined;
    }
  }

  public static async generatePdf(deliverableSheetId: number) {
    try {
      const data = await ResourceApi.getById(this.baseUrl, deliverableSheetId, `pdf/generate`);
      return data;
    } catch (error) {
      return undefined;
    }
  }

  public static async urlPdf(deliverableSheetId: number) {
    try {
      const data = await ResourceApi.getById(this.reportTechnicalUrl, deliverableSheetId, `upload`);
      return data.data;
    } catch (error) {
      return undefined;
    }
  }

  public static async saveContent(resource: string, data: any, contentType: string) {
    try {
      await fetch(resource, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: data,
      });
    } catch (error) {
      console.error(error);
    }
  }

  public static async getUrlToUploadPdf(deliverableSheetId: number) {
    try {
      const {
        data: { deliverable_sheet_pdf_presigned_uri: deliverableSheetPdfUri },
      }: { data: { deliverable_sheet_pdf_presigned_uri: string } } = await ResourceApi.put(
        this.baseUrl,
        deliverableSheetId,
        {},
        'pdf'
      );
      return deliverableSheetPdfUri;
    } catch (error) {
      return undefined;
    }
  }

  public static async fetchDeliverableSheet(
    orderId: number,
    deliverableSheetId: number
  ): Promise<DeliverableSheet | undefined> {
    try {
      const {
        data: { deliverable_sheet: deliverableSheetRetrieved },
      }: { data: { deliverable_sheet: DeliverableSheet } } = await ResourceApi.getById(
        this.baseUrl,
        deliverableSheetId,
        undefined,
        {
          join: [
            'deliverable-sheet-workunits',
            'deliverable-sheet-workunits.scope',
            'deliverable-sheet-workunits.deliverable_sheet_workunit_modifications',
            'deliverable-sheet-workunits.accountable',
            'bm',
          ],
        }
      );
      return deliverableSheetRetrieved;
    } catch (error) {
      return undefined;
    }
  }

  public static async fetchDeliverableSheetInOrder(
    orderId: number,
    size: number,
    page: number
  ): Promise<{ totalItems: number; datas: DeliverableSheet[]; totalPages: number; currentPage: number } | undefined> {
    try {
      const params = {
        size,
        order_id: orderId,
        page,
        is_archived: 0,
        join: ['client'],
        type: 'technical',
      };
      const {
        data: { totalItems, datas, totalPages, currentPage },
      } = await ResourceApi.get(this.baseUrl, params);
      return { totalItems, datas, totalPages, currentPage };
    } catch (error) {
      return { totalItems: 0, datas: [], totalPages: 0, currentPage: 0 };
    }
  }

  public static async fetchFinancialReportsInOrder(
    orderId: number,
    size: number,
    page: number
  ): Promise<{ totalItems: number; datas: DeliverableSheet[]; totalPages: number; currentPage: number } | undefined> {
    try {
      const params = {
        size,
        order_id: orderId,
        page,
        is_archived: 0,
        join: ['client'],
        type: 'financial',
      };
      const {
        data: { totalItems, datas, totalPages, currentPage },
      } = await ResourceApi.get(this.baseUrl, params);
      return { totalItems, datas, totalPages, currentPage };
    } catch (error) {
      return { totalItems: 0, datas: [], totalPages: 0, currentPage: 0 };
    }
  }

  public static async fetchAllMissions(
    deliverableSheetId: number,
    size: number,
    page: number,
    search: SearchParams
  ): Promise<
    { totalItems: number; datas: DeliverableSheetWorkunit[]; totalPages: number; currentPage: number } | undefined
  > {
    try {
      // eslint-disable-next-line max-len
      const params = {
        is_archived: 0,
        join: [
          'scope',
          'workunit',
          'mission-frequency',
          'mission-status',
          'mission-comments',
          'mission-comments.author',
          'mission-advancement',
          'consultant',
          'client',
        ],
        deliverable_sheet_id: deliverableSheetId,
        size,
        page,
        ...search,
      };

      const {
        data: { totalItems, datas, totalPages, currentPage },
      } = await ResourceApi.get(this.baseUrl, params);
      return { totalItems, datas, totalPages, currentPage };
    } catch (error) {
      return undefined;
    }
  }

  public static async getXlsx(deliverableSheetId: number) {
    try {
      const { data } = await ResourceApi.getById(this.baseUrl, deliverableSheetId, `xlsx`);
      return data;
    } catch (error) {
      return undefined;
    }
  }

  public static async cancelSending(deliverableSheetId: number) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await ResourceApi.patchById(this.baseUrl, deliverableSheetId, {
        state: ReportStatus.TO_BE_SENT,
        signed_by_DM: false,
        assesment: null,
        location: null,
        comment: null,
        signing_date: null,
        is_signed: false,
      });
      return data;
    } catch (error: unknown) {
      throw error;
    }
  }
}
