import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { filter, find, head } from 'lodash';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { RootState } from '../redux';

export const selectScopes = (state: RootState) =>
  filter(
    state.deliverable.order_scopes.map((order_scope) => order_scope.scope),
    (scope): scope is Scope => !!scope
  );

export const selectSelectedDeliverables = (state: RootState) =>
  getDeliverables(state.deliverable?.deliverables ?? [], state.deliverable.selectedDeliverablesModel);

export const selectActiveDeliverable = (state: RootState) =>
  head(getDeliverables(state.deliverable?.deliverables ?? [], state.deliverable.activeDeliverableModel));

export const selectCanSeletedItemsBeRated = (state: RootState) =>
  getDeliverables(state.deliverable?.deliverables ?? [], state.deliverable.selectedDeliverablesModel).every(
    (deliverable) =>
      deliverable.mission_advancement_id &&
      [MissionAdvancementPossibilities.RESERVE_CLIENT, MissionAdvancementPossibilities.ACCEPTATION_CLIENT].includes(
        deliverable.mission_advancement_id
      ) &&
      !deliverable.rating
  );

export const selectSelectedItemDeletable = (state: RootState) =>
  filter(
    getDeliverables(state.deliverable?.deliverables ?? [], state.deliverable.selectedDeliverablesModel),
    (deliverable) => !deliverable.workunit_id && !deliverable.mission_advancement_id
  );

export const selectSelectedItemEditable = (state: RootState) =>
  filter(
    getDeliverables(state.deliverable?.deliverables ?? [], state.deliverable.selectedDeliverablesModel),
    (deliverable) => !deliverable.workunit_id && !deliverable.mission_advancement_id
  );

const getDeliverables = (deliverables: OrderWorkunit[], ids: number[] | GridSelectionModel) =>
  filter(
    ids.map((id) => find(deliverables, ['id', id])),
    (deliverable): deliverable is OrderWorkunit => !!deliverable
  );
