import React from 'react';
import ManagementCloseDialog from './ManagementClose';
import ManagementDuplicationDialog from './ManagementDuplication';
import ManagementOpenDialog from './ManagementOpen';

const ManagementDialogs: React.FC = () => (
  <>
    <ManagementDuplicationDialog />
    <ManagementCloseDialog />
    <ManagementOpenDialog />
  </>
);

export default ManagementDialogs;
