import {
  Avatar,
  AvatarGroup,
  Box,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ProtectedLink from 'components/ProtectedLink';
import ToggleButton from 'components/ToggleButton';
import { trim } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from '../../../components';
import { HistogramIcon } from '../../../components/CustomIcons';
import { capitalize, numberWithSpaces, stringToColor } from '../../../helpers/format';
import useManagementRights from '../../../hooks/rights/useManagementRights';
import useUserRoles from '../../../hooks/useUserRoles';
import { useSelector } from '../../../store/hooks';
import useManagementHelper from '../../Management/services/useManagementHelper';
import FinancialGraph from '../OrderGraphs/FinancialGraph/FinancialGraph';
import OrderKpi from '../OrderKpi/OrderKpi';
import OrderMenu from '../OrderMenu/OrderMenu';
import { OrderCardProps } from './OrderCard';
import useOrderCardService from './useOrderCardService';

const OrderProductionCardContent: React.FC<OrderCardProps> = ({
  order,
  menu = true,
  stats = true,
  graph = true,
  kpi = true,
}) => {
  const [t] = useTranslation();
  const { app } = useSelector((state) => state);
  const { canShowStatOrder, canShowGraphOrder, canShowKpiOrder } = useManagementRights(order);
  const userRoles = useUserRoles();
  const { engagementDate, orderConsultantTooltipTitle, orderClient } = useOrderCardService(order);
  const { getColorDisplayed } = useManagementHelper();
  const pathname = `/${app.customer?.slug}/deliverables/${order.id}`;
  const [isKpiOpen, setIsKpiOpen] = useState(false);
  const [isGraphOpen, setIsGraphOpen] = useState(false);
  const devises = useSelector((state) => state.app.devises);
  const currentDevise = devises?.find((devise) => order.devise_name[0] === devise.name)?.symbol;

  return (
    <>
      <CardContent sx={{ position: 'relative' }}>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} md>
            <Grid container spacing={4} justifyContent="space-between" alignItems="center">
              <Grid item xs={12} md={6} lg={!userRoles.isConsultant && !userRoles.isCustomer ? 2 : 3}>
                <Typography noWrap>{t('Otp_ref')}</Typography>
                <ProtectedLink pathname={pathname}>
                  {order.affair ? (
                    <Tooltip title={trim([order.affair?.otp, order.affair?.name].join(' - '))}>
                      <div>
                        <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                          {order.affair?.otp}
                        </Typography>
                        <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                          {order.affair?.name}
                        </Typography>
                      </div>
                    </Tooltip>
                  ) : (
                    <div>
                      <Typography noWrap>{t('Affair_not_indicated')} </Typography>
                      <Box sx={{ height: '1.6rem' }} />
                    </div>
                  )}
                </ProtectedLink>
              </Grid>
              {!userRoles.isConsultant && !userRoles.isCustomer && (
                <Grid item xs={12} md={6} lg={2}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography>{t('Consultants')}</Typography>
                    <Tooltip placement="bottom-start" title={orderConsultantTooltipTitle}>
                      {order.consultants && order.consultants[0] ? (
                        <AvatarGroup max={3}>
                          {order.consultants.map((c) =>
                            c.avatar_uri ? (
                              <Avatar src={c.avatar_uri} key={c.id} />
                            ) : (
                              <Avatar
                                sx={{
                                  backgroundColor: stringToColor(c.first_name),
                                  color: stringToColor(c.first_name, 'color'),
                                }}
                                key={c.id}
                              >{`${capitalize(c.first_name.charAt(0))}${capitalize(c.last_name.charAt(0))}`}</Avatar>
                            )
                          )}
                        </AvatarGroup>
                      ) : (
                        <div>
                          <Typography sx={{ color: 'primary.main', fontWeight: 500 }}>-</Typography>
                          <Box sx={{ height: '1.6rem' }} />
                        </div>
                      )}
                    </Tooltip>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md={6} lg={!userRoles.isConsultant && !userRoles.isCustomer ? 2 : 3}>
                <Typography noWrap>{t('Client')}</Typography>
                <Tooltip title={orderClient()}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {orderClient()}
                  </Typography>
                </Tooltip>
                <Box sx={{ height: '1.6rem' }} />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography>{t('Project_ref')}</Typography>
                <Tooltip title={trim([order.name_spec, order.ref_spec].join(' - ')) ?? t<string>('Not_defined')}>
                  <div>
                    <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                      {order?.name_spec}
                    </Typography>
                    <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                      {order?.ref_spec}
                    </Typography>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Typography>{t('Engagement_date')}</Typography>
                <Tooltip title={engagementDate()}>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    {engagementDate()}
                  </Typography>
                </Tooltip>
                <Box sx={{ height: '1.6rem' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md="auto"
            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', ml: 'auto' }}
          >
            <Stack spacing={2} direction="row" alignItems="center">
              {graph && canShowGraphOrder && (
                <IconButton
                  aria-label="open-graph"
                  onClick={() => setIsGraphOpen(!isGraphOpen)}
                  color="primary"
                  size="large"
                >
                  <HistogramIcon />
                </IconButton>
              )}
              {kpi && canShowKpiOrder && (
                <ToggleButton isOpen={isKpiOpen} handleClick={() => setIsKpiOpen(!isKpiOpen)} />
              )}
              {menu && <OrderMenu order={order} />}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      {stats && canShowStatOrder && (
        <CardContent sx={{ pt: 0 }}>
          <Grid container spacing={{ xs: 3, lg: 16 }} alignItems="center">
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" color="textSecondary">
                  {t('Realised')}
                </Typography>
                <Typography>{`${
                  order.percentage_of_realised_deliverables ? order.percentage_of_realised_deliverables.toFixed(2) : 0
                }%`}</Typography>
              </Box>
              <ProgressBar
                value={order.percentage_of_realised_deliverables || 0}
                color={getColorDisplayed(order.percentage_of_realised_deliverables)}
              />
              {!userRoles.isCustomer && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {`${numberWithSpaces(order.realised_deliverables_price)} / ${numberWithSpaces(order?.amount_price)} ${
                    currentDevise && currentDevise !== 'E' ? currentDevise.toLowerCase() : '€'
                  }`}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" color="textSecondary">
                  {t('Accepted')}
                </Typography>
                <Typography>{`${
                  order.percentage_of_accepted_deliverables ? order.percentage_of_accepted_deliverables.toFixed(2) : 0
                }%`}</Typography>
              </Box>
              <ProgressBar
                value={order.percentage_of_accepted_deliverables || 0}
                color={getColorDisplayed(order.percentage_of_accepted_deliverables)}
              />
              {!userRoles.isCustomer && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {`${numberWithSpaces(order.accepted_deliverables_price)} / ${numberWithSpaces(
                    order?.amount_price || 0
                  )}  ${currentDevise && currentDevise !== 'E' ? currentDevise.toLowerCase() : '€'}`}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      )}
      {graph && canShowGraphOrder && (
        <Collapse in={isGraphOpen} timeout={500} unmountOnExit>
          <CardContent sx={{ p: 0 }}>
            <FinancialGraph order_id={order?.id} orderPrice={order?.price || 1} />
          </CardContent>
        </Collapse>
      )}
      {kpi && canShowKpiOrder && (
        <Collapse in={isKpiOpen} timeout={500} unmountOnExit>
          <CardContent sx={{ p: 0 }}>
            <OrderKpi order={order} />
          </CardContent>
        </Collapse>
      )}
    </>
  );
};

export default OrderProductionCardContent;
