import { FilterActions } from '../actions/filterActions';
import { RootActions } from '../redux';
import { clearSelectedFilters } from '../services/filterServices';
import { filterState, FilterState } from '../states/filterState';

const filterReducer = (state = filterState, action: RootActions): FilterState => {
  switch (action.type) {
    case FilterActions.UPDATE_PARAMS_FILTER:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      };
    case FilterActions.UPDATE_ITEMS_FILTER:
      return {
        ...state,
        items: action.payload,
      };
    case FilterActions.SELECT_FILTER:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
        },
      };
    case FilterActions.CLEAR_SELECTED_FILTER:
      return {
        ...state,
        selected: clearSelectedFilters(state.selected),
      };
    case FilterActions.RESET_FILTER:
      return {
        params: undefined,
        items: [],
        selected: {},
      };
    default:
      return state;
  }
};

export default filterReducer;
