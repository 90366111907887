import { Box, Checkbox, FormControlLabel, FormGroup, LinearProgress } from '@mui/material';
import {
  DataGridProProps,
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridRowClassNameParams,
  GridSelectionModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro/DataGridPro/DataGridPro';
import { difference } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  changePageAction,
  changePageSizeAction,
  excludeDeliverablesAction,
  selectAllDeliverablesAction,
  selectDeliverablesAction,
  sortDeliverablesAction,
} from 'store/actions/deliverableActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { DeliverableTableType } from 'types/enums/DeliverableTableType';
import { deliverableColumnsFields, useColumns } from '../services/deliverableTableService';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const CustomToolbar = ({ setFilterButtonEl }: { setFilterButtonEl: any }) => {
  const { dispatch, state } = useAppState();
  const { deliverable } = state;

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(selectDeliverablesAction([]));
    dispatch(selectAllDeliverablesAction(e.currentTarget.checked));
  };

  // TODO : Fix GridToolbarColumnsButton props, they are temporary set to undefined
  return (
    <Box sx={{ display: 'flex', pl: 1.75 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox color="primary" checked={deliverable.selectedAllDeliverables} onChange={handleSelectAllChange} />
          }
          label="Select All"
        />
      </FormGroup>
      <GridToolbarContainer sx={{ position: 'relative', ml: 'auto' }}>
        <GridToolbarColumnsButton
          ref={setFilterButtonEl}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        />
      </GridToolbarContainer>
    </Box>
  );
};

const DeliverableTable: React.FC = () => {
  const { dispatch } = useAppState();
  const state = useSelector((state) => state.deliverable);
  const apiRef = useGridApiRef();
  const loadings = useSelector((state) => state.loadings);
  const getLocalStorageColumnsStatusKey = (): string =>
    state.currentTableType ? `${state.currentTableType}ColumnsStatus` : '';
  const getLocalStorageColumnsOrders = useCallback(
    (): string => (state.currentTableType ? `${state.currentTableType}ColumnsOrders` : ''),
    [state.currentTableType]
  );
  const columns = useColumns(deliverableColumnsFields);

  const initialColumnVisibilitySettings = {
    content: true,
    content2: false,
    content3: false,
    content4: false,
    content5: false,
    amount: false,
    scope: true,
    workunit_desc: false,
    deliverableDate: true,
    forcastedDate: true,
    __check__: true,
    tags: true,
    workunit_reference: true,
    complexity: false,
    cancellation_reason: false,
    modification_reason: false,
    client: true,
    delivery_manager: true,
    submitted: true,
    dm_validation: true,
    client_acceptance: true,
    rating: true,
    comments: true,
    actions: true,
    workunit_name: true,
    consultant: true,
    workload: false,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const localStorageValue = localStorage.getItem(getLocalStorageColumnsStatusKey());
    if (localStorageValue) {
      return JSON.parse(localStorageValue);
    }
    return initialColumnVisibilitySettings;
  });

  const [columnsOrders, setColumnsOrders] = useState(
    JSON.parse(localStorage.getItem(getLocalStorageColumnsOrders()) || '{}')
  );
  const [currentPage, setCurrentPage] = useState(0);

  const onColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(model);
    localStorage.setItem(getLocalStorageColumnsStatusKey(), JSON.stringify(model));
  };
  const onColumnOrderChange = useCallback(
    (params: GridColumnOrderChangeParams, event: MuiEvent, details: any) => {
      const { field, targetIndex } = params; // Destructure column Name // and it's target Index
      if (columnsOrders[field] === targetIndex) return;
      setColumnsOrders((prevState: any) => ({
        ...prevState,
        [field]: targetIndex,
      }));
      localStorage.setItem(getLocalStorageColumnsOrders(), JSON.stringify(columnsOrders));
    },
    [columnsOrders, getLocalStorageColumnsOrders]
  );

  useEffect(() => {
    Object.entries(columnsOrders).forEach(([key, value]) => {
      const actualIndex = apiRef.current.getColumnIndex(key);
      if (actualIndex !== value) apiRef.current.setColumnIndex(key, value as number);
    });
  }, [columnsOrders]);

  const onPageSizeChange = (pageSize: number) => {
    dispatch(changePageSizeAction(pageSize));
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(changePageAction(currentPage));
    }, 500);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const onSelectionModelChange = (selectionModel: GridSelectionModel, details: GridCallbackDetails) => {
    const deliverablesIds = state.deliverables?.map((deliverable) => deliverable.id) || [];

    const unselected = difference(deliverablesIds, selectionModel);
    const oldUnselected = state.excludedDeliverables.filter(
      (excludedDeliverable) =>
        !selectionModel.includes(excludedDeliverable) && !deliverablesIds.includes(Number(excludedDeliverable))
    );
    const everyUnselected = [...oldUnselected, ...unselected];
    dispatch(excludeDeliverablesAction(everyUnselected));
    dispatch(selectDeliverablesAction(selectionModel));
  };

  const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    dispatch(sortDeliverablesAction(sortModel));
  }, []);

  const getRowClassName = useCallback((params: GridRowClassNameParams) => {
    if (params.row.label && params.row.label.includes('cancelled')) return 'Mui-disabled';
    return params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd';
  }, []);

  const dataTableProps: Omit<DataGridProProps, 'columns'> = {
    rows: state.deliverables ?? [],
    initialState: {
      columns: {
        orderedFields: columnsOrders,
      },
    },
    onColumnVisibilityModelChange,
    columnVisibilityModel,
    apiRef,
    autoHeight: true,
    pagination: true,
    disableColumnMenu: true,
    headerHeight: 100,
    rowHeight: 80,
    onPageSizeChange,
    pageSize: state.pageSize,
    rowsPerPageOptions: [10, 25, 50, 100, 200],
    page: currentPage,
    paginationMode: 'server',
    sortingMode: 'server',
    keepNonExistentRowsSelected: true,
    disableSelectionOnClick: true,
    rowCount: state.totalItems,
    selectionModel: state.selectedDeliverablesModel,
    onPageChange,
    onSortModelChange,
    onSelectionModelChange,
    getRowClassName,
    onColumnOrderChange,
    // checkboxSelection: !state.selectedAllDeliverables, // TODO ignore unselect or select after a select all
    checkboxSelection: true, // TODO ignore unselect or select after a select all
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(((loadings?.table ?? 0) > 0 && (loadings?.api ?? 0) > 0) || state.deliverables === null);
  }, [isLoading, loadings?.api, loadings?.table, state.deliverables]);
  const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | null>(null);
  return (
    <>
      {[DeliverableTableType.TECHNICAL, DeliverableTableType.FINANCIAL].map((tableType) => (
        <div key={tableType}>
          {state.currentTableType === tableType && (
            <DataGrid
              columns={columns[tableType]}
              components={{
                Toolbar: CustomToolbar,
                LoadingOverlay: LinearProgress,
              }}
              componentsProps={{
                loadingOverlay: { style: { zIndex: 9999 } },
                panel: {
                  anchorEl: filterButtonEl,
                  placement: 'bottom-end',
                },
                toolbar: {
                  setFilterButtonEl,
                },
              }}
              loading={isLoading}
              className={state.selectedAllDeliverables ? 'test' : ''}
              {...dataTableProps}
              sx={{
                '& .Mui-disabled': {
                  background: '#e1e5ef',
                  opacity: '.9',
                  pointerEvents: 'initial',
                },
                overflow: 'auto',
                mb: { xs: 30, md: 10 },
                ...(state.selectedAllDeliverables && {
                  '& .MuiDataGrid-cellCheckbox .MuiCheckbox-root.Mui-checked': {
                    color: '#ff0000',
                  },
                  '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked': {
                    color: '#ff0000',
                  },
                  '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.MuiCheckbox-indeterminate': {
                    color: '#ff0000',
                  },
                }),
              }}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default DeliverableTable;
