import { RestartAlt } from '@mui/icons-material';
import MomentUtils from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { IconButton, InputAdornment, InputLabel, TextField } from '@mui/material';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from './CustomIcons';

type InputLabelProps = {
  label: string;
  name: string;
  id: string;
  placeholder: string;
  onchange: (name: string, date: Moment | null) => void | null;
  required?: boolean;
  value: string | null | undefined;
  min?: string | null;
  max?: string | null;
  classNameLabel?: string;
  disabled?: boolean;
  canEmpty?: boolean;
  showError?: boolean;
  variant?: any;
  reset?: Moment | null;
};
const InputDate = (props: InputLabelProps) => {
  const {
    label,
    name,
    id,
    placeholder,
    onchange,
    required = false,
    value,
    min,
    max,
    disabled = false,
    canEmpty = false,
    showError = false,
    variant,
    reset,
  } = props;
  const [t] = useTranslation();
  const handleReset = () => {
    if (reset && moment(reset).isValid()) {
      const formattedReset: Moment = moment(reset);
      onchange(name, formattedReset);
    } else onchange(name, null);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [localValue, setLocalValue] = useState<string | null | undefined>(value);

  const [isError, setIsError] = useState(false);
  const [textErrorHelper, setTextErrorHelper] = useState<string | null>(null);
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    const isValidDate = moment(localValue).isValid() || (canEmpty && value === '');
    if (min === null || localValue === null) {
      setTextErrorHelper('');

      return setIsError(false);
    }
    if (!isValidDate && showError) {
      setTextErrorHelper(t('Date_invalid'));
      return setIsError(true);
    }
    if (min && moment(min).subtract('1', 'd').unix() >= moment(localValue).unix()) {
      setTextErrorHelper(`${t('Date_should_be_superior_to')} ${moment(min).format('DD/MM/yyyy')}`);

      return setIsError(true);
    }

    if (max && moment(max).add('1', 'd').unix() < moment(localValue).unix()) {
      setTextErrorHelper(`${t('Date_should_be_inferior_to')} ${moment(max).format('DD/MM/yyyy')}`);

      return setIsError(true);
    }

    setTextErrorHelper(null);
    return setIsError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max, localValue]);

  const onChangeHandler = (name: string, date: Moment) => {
    if (date) {
      setLocalValue(date.format('DD/MM/YYYY'));
      onchange(name, date);
    } else {
      setLocalValue(null);
      onchange(name, null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <DatePicker
        value={value || null}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        disabled={disabled}
        minDate={min ? moment(min) : null}
        maxDate={max ? moment(max) : null}
        inputFormat="DD/MM/YYYY"
        toolbarPlaceholder={placeholder}
        className="datepicker-custom"
        onChange={(date: unknown) => onChangeHandler(name, date as unknown as Moment)}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            disabled={disabled}
            required={required}
            error={isError}
            color={required ? 'primary' : undefined}
            helperText={textErrorHelper}
            fullWidth
            variant={variant}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleReset} size="small">
                    <RestartAlt />
                  </IconButton>
                  <IconButton edge="end" onClick={handleOpen} size="small" color="primary">
                    <CalendarIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
export default InputDate;
