import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Pagination } from '@mui/material';
import OrderApiService from 'api/OrderApiService';
import UserApiService from 'api/UserApiService';
import { Layout } from 'components';
import DisplayLoadingOrNotResult from 'components/DisplayLoadingOrNotResult';
import useManagementHelper from 'features/Management/services/useManagementHelper';
import OrderCard from 'features/Orders/OrderCard/OrderCard';
import useCancelApi from 'hooks/useCancelApi';
import useUserRoles from 'hooks/useUserRoles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { resetFilterAction } from 'store/actions/filterActions';
import { changeCurrentTabAction, changePageAction, injectClientsAction } from 'store/actions/managementActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { DeleteIcon } from '../../components/CustomIcons';
import ManagementDialogs from './ManagementDialogs';
import ManagementDrawers from './ManagementDrawers';
import ManagementFilter from './ManagementFilters/ManagementFilter';
import ManagementHeadband from './ManagementHead/ManagementHead';
import ManagementHeader from './ManagementHead/ManagementHeader/ManagementHeader';
import useManagementService from './services/useManagementService';

const ManagementPage: React.FC<RouteComponentProps> = ({ match }) => {
  const [t] = useTranslation();
  const { search } = useLocation();
  const { dispatch } = useAppState();
  const { cancelRequests } = useCancelApi();
  const [currentToAssignCount, setCurrentToAssignCount] = useState(0);
  const { fetchOrders, handleManagementSearchParams, goBackStatus } = useManagementService();
  const { app, filter, management } = useSelector((state) => state);
  const managementOrders = useSelector((state) => state.management.orders);
  const managementPageHandlerCarousel = useSelector((state) => state.management.pageHandler.currentTab);
  const { getPageTitleName, setCarousel } = useManagementHelper();
  const pageName = useCallback(() => getPageTitleName(), [getPageTitleName]);
  const userRoles = useUserRoles();

  const fetchCountOfToAssignOrder = useCallback(async () => {
    if (pageName() === 'Management') {
      const { totalItems } = await OrderApiService.fetchCountOfToAssignOrder(app?.customer?.id as number);
      setCurrentToAssignCount(totalItems);
    }
  }, [app?.customer?.id, pageName, managementPageHandlerCarousel]);

  const fetchClients = async () => {
    if (app.customer?.id) {
      const clients = await UserApiService.getClientsOnCustomer(app.customer.id);
      dispatch(injectClientsAction(clients));
    }
  };

  useEffect(() => {
    dispatch(resetFilterAction());
    fetchClients();
    fetchCountOfToAssignOrder();
    const searchParams = new URLSearchParams(search);
    const status = searchParams.get('status');
    if (status) {
      dispatch(changeCurrentTabAction(status as OrdersTabState));
    }
  }, []);

  useEffect(() => {
    fetchCountOfToAssignOrder();
  }, [fetchCountOfToAssignOrder]);

  useEffect(
    () => () => {
      dispatch(resetFilterAction());
    },
    []
  );
  useEffect(() => {
    handleManagementSearchParams();
  }, [
    filter.selected,
    management.pageHandler.currentTab,
    management.pageHandler.currentPage,
    cancelRequests,
    handleManagementSearchParams,
    dispatch,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const url = new URL(window.location.href);
      let jsonObject: any;
      if (management.searchParams) {
        console.log(`useEffect.fetchOrders: ${JSON.stringify(management.searchParams)}`);
        console.log(`useEffect.fetchOrders id: ${management.searchParams.id}`);
        jsonObject = JSON.parse(JSON.stringify(management.searchParams));
        console.log(`json :  ${jsonObject['reference,ref_spec,name_spec']}`);
        if (
          url.toString().indexOf('/orders/production?status=production&') !== -1 &&
          jsonObject['reference,ref_spec,name_spec'] === undefined &&
          management.searchParams.id === undefined
        ) {
          console.log(` ${url} skipped fetchOrders api call`);
          return;
        }
        fetchOrders();
      }
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [fetchOrders, management.searchParams]);

  const handleLayoutActionButton = useCallback(() => {
    if (userRoles.isClient || userRoles.isCustomer || userRoles.isConsultant) return <></>;
    if (management.pageHandler.currentTab === OrdersTabState.ARCHIVED) {
      return (
        <Button onClick={() => setCarousel(goBackStatus())} startIcon={<ArrowBackIos />}>
          {t('Back')}
        </Button>
      );
    }
    return (
      <Button onClick={() => setCarousel(OrdersTabState.ARCHIVED)} startIcon={<DeleteIcon />}>
        {t('archives')}
      </Button>
    );
  }, [
    goBackStatus,
    management.pageHandler.currentTab,
    setCarousel,
    t,
    userRoles.isClient,
    userRoles.isConsultant,
    userRoles.isCustomer,
  ]);

  return (
    <Layout name={pageName()} path="/orders" subTitleElement={handleLayoutActionButton()}>
      <Box id="management_page">
        <ManagementHeadband currentToAssignCount={currentToAssignCount} />
        <ManagementHeader />
        {managementOrders && managementOrders?.length > 0 && (
          <>
            {managementOrders?.map((order) => (
              <OrderCard key={order.id} order={order} graph={false} />
            ))}
            <Pagination
              count={management.pageHandler.totalPages}
              page={management.pageHandler.currentPage}
              onChange={(ev, page) => dispatch(changePageAction(page))}
            />
          </>
        )}
        {managementOrders && managementOrders.length === 0 && <DisplayLoadingOrNotResult items={managementOrders} />}
      </Box>
      <ManagementDialogs />
      <ManagementDrawers />
      <ManagementFilter />
    </Layout>
  );
};

export default ManagementPage;
