import { Autocomplete, InputLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import Affair from 'types/entities/Affair';
import Devise from 'types/entities/Devise';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import Workunit from 'types/entities/Workunit';

export type SelectTypeOptions = Affair | Scope | Devise | User | Workunit;
export type PropertyNameOptions = 'affair' | 'scope' | 'devise' | 'customer' | 'workunit';

type SelectProps = {
  options: SelectTypeOptions[];
  selectedOption: SelectTypeOptions | undefined | null;
  defaultOption?: SelectTypeOptions;
  optionsLabels: keyof SelectTypeOptions | 'name' | 'otp' | 'reference' | 'value';
  label: string;
  handleSelectOptions: (opt: SelectTypeOptions | null, propertyName: PropertyNameOptions) => void;
  propertyName: PropertyNameOptions;
  required?: boolean;
  disabled?: boolean;
  classNameLabel?: string;
  disableClearable?: boolean;
  id?: string;
  variant?: any;
};
const AutocompleteCustom = (props: SelectProps) => {
  const {
    options,
    selectedOption,
    handleSelectOptions,
    label,
    optionsLabels,
    propertyName,
    required = false,
    disabled = false,
    defaultOption,
    classNameLabel = '',
    disableClearable = false,
    id,
    variant,
  } = props;
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <>
      <InputLabel htmlFor={id}>
        {label} {required && '*'}
      </InputLabel>
      <Autocomplete
        options={options || []}
        autoHighlight
        fullWidth
        getOptionLabel={(option: any) => (option ? option[optionsLabels] || option.name : defaultOption)}
        disableClearable={disableClearable}
        inputValue={inputValue}
        onInputChange={(e, value) => {
          setInputValue(value);
        }}
        isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
        disabled={disabled}
        value={selectedOption || null}
        defaultValue={defaultOption || selectedOption}
        onChange={(e, value) => handleSelectOptions(value, propertyName)}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            required={required}
            disabled={disabled}
            variant={variant}
            inputProps={{
              autoComplete: '',
              ...params.inputProps,
            }}
          />
        )}
      />
    </>
  );
};
export default AutocompleteCustom;
