import Order from 'types/entities/Order';
import User from 'types/entities/User';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { SearchParams } from '../../api/ResourceAPI';

export interface ManagementState {
  orders: Order[] | null;
  clients: User[] | null;
  searchParams: SearchParams | null;
  pageHandler: {
    currentTab: OrdersTabState;
    currentPage: number;
    totalPages: number;
  };
  activeOrderId: Order['id'] | null;
}

export const managementState: ManagementState = {
  orders: null,
  clients: null,
  searchParams: null,
  pageHandler: {
    currentTab: OrdersTabState.NULL,
    currentPage: 1,
    totalPages: 0,
  },
  activeOrderId: null,
};
