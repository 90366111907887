import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { BottomNavigation, Box, ButtonProps, styled, Tooltip, Typography } from '@mui/material';
import useDeliverableRights from 'hooks/rights/useDeliverableRights';
import useUserRoles from 'hooks/useUserRoles';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectAllDeliverablesAction, selectDeliverablesAction } from 'store/actions/deliverableActions';
import { openDialogAction } from 'store/actions/dialogsActions';
import { openDrawerAction } from 'store/actions/drawerActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { selectSelectedDeliverables } from 'store/selectors/deliverableSelectors';
import { DeliverableTableType } from 'types/enums/DeliverableTableType';
import useDeliverableService from '../hooks/useDeliverableService';

const DeliverableQuickActions: React.FC = () => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const state = useSelector((state) => state.deliverable);
  const userRights = useUserRoles();
  const loadings = useSelector((state) => state.loadings);
  const selectedDeliverables = useSelector(selectSelectedDeliverables);
  const { generateExcelReport } = useDeliverableService();
  const { canUseQuickActions, canEdit, canExport, canExportGR, canCreateDeliverableSheet } = useDeliverableRights(
    state.selectedAllDeliverables && !userRights.isClient ? [] : selectedDeliverables
  );

  const handleActions = async (item: any) => {
    if (item.loadingName) dispatch(addLoadingAction(`action${item.loadingName}`));
    await item.action();
    if (item.loadingName) dispatch(removeLoadingAction(`action${item.loadingName}`));
  };

  const exportExcelGR = useCallback(async () => {
    await generateExcelReport('financial-reports');
  }, [generateExcelReport]);

  const exportExcelReport = useCallback(async () => {
    await generateExcelReport('deliverables');
  }, [generateExcelReport]);

  const createSheetReport = useCallback(async () => {
    dispatch(openDialogAction({ name: 'deliverableCreateReportConfirm' }));
  }, [dispatch]);

  const openActionsDrawer = useCallback(async () => {
    dispatch(openDrawerAction({ name: 'deliverableActions' }));
  }, [dispatch]);

  const openEditDeliverableDrawer = useCallback(async () => {
    dispatch(
      openDrawerAction({
        name: 'deliverableEdit',
        data: state.selectedDeliverablesModel,
      })
    );
  }, [dispatch, state.selectedDeliverablesModel]);

  const actions = useMemo(
    () => [
      {
        action: exportExcelGR,
        permission: canExportGR,
        label: t('Export GR excel'),
        loadingName: 'exportExcelGR',
      },
      {
        action: exportExcelReport,
        permission: state.currentTableType === DeliverableTableType.TECHNICAL && canExport,
        label: t('Export excel report'),
        loadingName: 'exportExcelReport',
        icon: <Edit />,
      },
      /*  {
        action: createSheetReport,
        permission: state.currentTableType === DeliverableTableType.TECHNICAL && canCreateDeliverableSheet,
        label: t('Create report'),
        loadingName: 'createSheetReport',
      }, */
      {
        action: openEditDeliverableDrawer,
        permission: state.currentTableType === DeliverableTableType.TECHNICAL && canEdit,
        label: t('Mass edit'),
      },
      {
        action: openActionsDrawer,
        permission:
          state.currentTableType === DeliverableTableType.TECHNICAL &&
          canUseQuickActions &&
          !state.selectedAllDeliverables,
        label: t('Quick_action'),
      },
    ],
    [
      exportExcelGR,
      canExportGR,
      t,
      exportExcelReport,
      state.currentTableType,
      canExport,
      createSheetReport,
      canCreateDeliverableSheet,
      openEditDeliverableDrawer,
      canEdit,
      openActionsDrawer,
      canUseQuickActions,
    ]
  );

  const NavigationBottom = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  }));

  const actionsAvailables = useMemo(() => actions.filter((item) => item.permission !== false), [actions]);
  const selectedItems = () => {
    if (state.selectedAllDeliverables) {
      if (state.selectedDeliverablesModel.length > 0) {
        return `${state.totalItems - state.selectedDeliverablesModel.length} items selected (${
          state.selectedDeliverablesModel.length
        } excluded)`;
      }
      return `All selected (${state.totalItems} items)`;
    }

    return `${state.selectedDeliverablesModel.length} ${t('items_selected')}`;
  };
  return (
    <>
      {canUseQuickActions &&
        actionsAvailables.length &&
        (!!state.selectedDeliverablesModel.length || state.selectedAllDeliverables) && (
          <BottomNavigation
            sx={{
              borderRadius: '12px',
              backgroundColor: 'primary.main',
              position: 'fixed',
              flexDirection: { xs: 'column', md: 'row' },
              bottom: '10px',
              right: '25px',
              left: { xs: '25px', md: '170px' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
              color: '#FFFFFF',
              height: 'auto',
            }}
          >
            <Typography sx={{ fontWeight: 500, mb: { xs: 1, md: 0 } }}>{selectedItems()}</Typography>
            <Tooltip title="Affect every page">
              <NavigationBottom
                onClick={() => {
                  dispatch(selectAllDeliverablesAction(false));
                  dispatch(selectDeliverablesAction([]));
                }}
                sx={{ ml: 1, mb: { xs: 1, lg: 0 } }}
                size="small"
              >
                {t('Unselect all')}
              </NavigationBottom>
            </Tooltip>
            <Box
              sx={{ ml: 'auto', display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, flexWrap: 'wrap' }}
            >
              {actionsAvailables.map((item) => (
                <NavigationBottom
                  key={item.label}
                  onClick={() => handleActions(item)}
                  sx={{ ml: 1, mb: { xs: 1, lg: 0 } }}
                  size="small"
                  loading={!!loadings[`action${item.loadingName}`]}
                >
                  {item.label}
                </NavigationBottom>
              ))}
            </Box>
          </BottomNavigation>
        )}
    </>
  );
};

export default DeliverableQuickActions;
