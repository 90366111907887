import { FilterItem } from 'types/models/FilterItem';
import { SelectedFiltersList } from 'types/models/SelectedFiltersList';

export const FilterActions = {
  UPDATE_PARAMS_FILTER: 'UPDATE_PARAMS_FILTER',
  UPDATE_ITEMS_FILTER: 'UPDATE_ITEMS_FILTER',
  SELECT_FILTER: 'SELECT_FILTER',
  CLEAR_SELECTED_FILTER: 'CLEAR_SELECTED_FILTER',
  RESET_FILTER: 'RESET_FILTER',
} as const;

export const updateParamsFilterAction = (payload: any) => ({
  type: FilterActions.UPDATE_PARAMS_FILTER,
  payload,
});

export const updateItemsFilterAction = (payload: FilterItem[]) => ({
  type: FilterActions.UPDATE_ITEMS_FILTER,
  payload,
});

export const selectFilterAction = (payload: SelectedFiltersList) => ({
  type: FilterActions.SELECT_FILTER,
  payload,
});

export const clearSelectedFilterAction = () => ({
  type: FilterActions.CLEAR_SELECTED_FILTER,
});

export const resetFilterAction = () => ({
  type: FilterActions.RESET_FILTER,
});

export const filterActions = {
  updateParamsFilterAction,
  updateItemsFilterAction,
  selectFilterAction,
  clearSelectedFilterAction,
  resetFilterAction,
};
