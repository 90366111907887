import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton, Menu, Tooltip } from '@mui/material';
import { EditIcon, UserIcon } from 'components/CustomIcons';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import User from 'types/entities/User';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { Rating as RatingType } from 'types/enums/Rating';
import Rating from './Rating';

type OrderWorkUnitSummaryRowType = {
  id: number;
  name: string;
  workunits: OrderWorkunit[];
  accountable_id: number;
  accountable: User;
  ref_order: string;
  ref_work: string;
  order_scope_id: number;
  rating: string;
};

type RateComponentProps = {
  row?: OrderWorkunit | OrderWorkUnitSummaryRowType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setRating: (orderWorkunit: any, rate: RatingType) => Promise<void>;
  disabled?: boolean;
  isPartial?: boolean;
};

const RateComponent = (props: RateComponentProps) => {
  const { row, setRating, disabled, isPartial = false } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function isOrderWorkunit(arg: OrderWorkunit | OrderWorkUnitSummaryRowType): arg is OrderWorkunit {
    return Object.keys(arg).includes('mission_advancement_id');
  }

  const emptyCell = <RemoveIcon color="disabled" />;
  const getStringToMap = () => {
    if (row && isOrderWorkunit(row)) {
      if (row.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_CLIENT) return [RatingType.E];
      if (row?.mission_advancement_id && row.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_CLIENT)
        return [RatingType.A, RatingType.B, RatingType.C, RatingType.D];
    }

    return isPartial
      ? [RatingType.A, RatingType.B, RatingType.C]
      : [RatingType.A, RatingType.B, RatingType.C, RatingType.D, RatingType.E];
  };
  const stringsToMap = getStringToMap();
  const renderButton = () => {
    if (!disabled && row && !row.rating) {
      return <EditIcon fontSize="small" />;
    }

    if (row && row.rating) {
      return <Rating size="small" rating={row?.rating} />;
    }
    if ((!row || !row.rating) && disabled) {
      return emptyCell;
    }
    return emptyCell;
  };
  const [t] = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState('');
  const handleTooltip = (rate: string) => {
    setTooltipOpen(rate);
  };

  const getTooltip = (rating: string) => {
    if (rating === 'A') return t('Excellent');
    if (rating === 'B') return t('Above_expectations');
    if (rating === 'C') return t('Average');
    if (rating === 'D') return t('Below_expectations');
    if (rating === 'E') return t('Not_Acceptable');
    return '';
  };

  return (
    <div style={{ maxWidth: '56.5px', maxHeight: '60px' }}>
      <Tooltip
        title={getTooltip(row?.rating as string) as string}
        key={`${row?.rating as string}`}
        open={tooltipOpen === (row?.rating as string)}
        placement="top"
        PopperProps={{ style: { pointerEvents: 'none', marginTop: '40px' } }}
      >
        <span
          onMouseEnter={() => {
            handleTooltip(row?.rating as string);
          }}
          onBlur={() => handleTooltip('')}
          onMouseLeave={() => handleTooltip('')}
        >
          <IconButton
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            disabled={disabled}
            style={isPartial ? { border: '1px solid #dfd8d8', borderRadius: '0', marginLeft: '1rem' } : {}}
            size="large"
            color="primary"
          >
            {/* {row && row?.rating && !disabled && <Rating size="small" rating={row?.rating} />}
        {row && !row?.rating && !disabled && <RateReviewOutlined />}
        {disabled && emptyCell} */}
            {renderButton()}
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            style={{ zIndex: 14 }}
          >
            {stringsToMap.map((rate, index) => (
              <IconButton
                disabled={disabled}
                key={rate}
                onClick={() => {
                  setRating(row as OrderWorkunit | OrderWorkUnitSummaryRowType, rate);
                  handleClose();
                }}
                size="large"
              >
                <Rating size="small" rating={rate} open={open} handleTooltipParent={handleTooltip} />
              </IconButton>
            ))}
          </Menu>
        </span>
      </Tooltip>
      {row?.rating && (row as OrderWorkunit).reviewer && !(row as OrderWorkunit).reviewer?.is_client && (
        <Tooltip
          title={t('assessed_dm') as string}
          placement="top"
          arrow
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                textAlign: 'center',
              },
            },
          }}
        >
          <div className="icon-container" style={{ position: 'relative', top: -25, left: 35, width: 15 }}>
            <UserIcon fontSize="small" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
// eslint-disable-next-line react-hooks/exhaustive-deps
export default (props: RateComponentProps) => useMemo(() => <RateComponent {...props} />, [props.row, props.disabled]);
