import MissionFrequency from 'types/entities/MissionFrequency';
import MissionStatus from 'types/entities/MissionStatus';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { OrderWorkunitOrigin } from 'types/enums/OrderWorkunitOrigin';
import MissionEditsParams from 'types/models/MissionEditsParams';

export function isActiveFrequency(deliverableEditParams: MissionEditsParams, frequency: MissionFrequency) {
  if (
    deliverableEditParams?.['mission-frequency'] &&
    deliverableEditParams?.['mission-frequency'].id === frequency.id
  ) {
    return 'checked';
  }

  return '';
}

export function isActiveStatus(deliverableEditParams: MissionEditsParams, status: MissionStatus) {
  if (deliverableEditParams?.['mission-status'] && deliverableEditParams?.['mission-status'].id === status.id) {
    return 'active-checkbox';
  }
  return '';
}

export const isEveryDeliverableNotFromQuote = (deliverables: OrderWorkunit[]) =>
  deliverables.every((deliverable) => !isDeliverableFromQuote(deliverable));

export const isEveryDeliverableCustom = (deliverables: OrderWorkunit[]) =>
  deliverables.every((deliverable) => deliverable.label?.includes('custom'));

export const isDeliverableFromQuote = (deliverable: OrderWorkunit) => deliverable.origin === OrderWorkunitOrigin.QUOTE;

export const isEveryDeliverableFromQuote = (deliverables: OrderWorkunit[]) =>
  deliverables.every(isDeliverableFromQuote);

export const isEveryDeliverableUnsubmitted = (deliverables: OrderWorkunit[]) =>
  deliverables.every(
    (deliverable) =>
      (!deliverable.mission_advancement_id ||
        deliverable.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_TL) &&
      !isDeliverableCancelled(deliverable)
  );

export const isDeliverableValidated = (deliverable: OrderWorkunit) => {
  if (!deliverable.mission_advancement_id) return false;
  return deliverable.mission_advancement_id >= MissionAdvancementPossibilities.RESERVE_TL;
};

export const isEveryDeliverableTlValidated = (deliverables: OrderWorkunit[]) =>
  deliverables.every((d) => isDeliverableValidated(d));

export const canEveryDeliverableHaveDateChange = (deliverables: OrderWorkunit[]) =>
  deliverables.every(
    (deliverable) =>
      !deliverable.mission_advancement_id ||
      deliverable.mission_advancement_id <= MissionAdvancementPossibilities.RESERVE_TL
  );
export const canDeliverableBeSubmitted = (deliverable: OrderWorkunit) =>
  (!deliverable?.mission_advancement_id ||
    deliverable?.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_TL) &&
  !isDeliverableCancelled(deliverable);

export const canDeliverableBeAccepted = (deliverable: OrderWorkunit) =>
  deliverable?.mission_advancement_id &&
  deliverable?.mission_advancement_id !== MissionAdvancementPossibilities.REFUSED_TL &&
  deliverable?.mission_advancement_id <= MissionAdvancementPossibilities.ACCEPTATION_TL;

export const canEveryDeliverableBeSubmitted = (deliverables: OrderWorkunit[]) =>
  deliverables.every(canDeliverableBeSubmitted);

export const canEveryDeliverableBeAccepted = (deliverables: OrderWorkunit[]) =>
  deliverables.every(canDeliverableBeAccepted);

export const isNotEveryCustomDeliverablesAboveSubmittedStatus = (deliverables: OrderWorkunit[]) =>
  !deliverables.every(isDeliverableAboveSubmittedStatus) && !deliverables.every(isDeliverableCancelled);

export const isDeliverableAboveSubmittedStatus = (deliverable: OrderWorkunit) =>
  deliverable?.mission_advancement_id &&
  deliverable?.mission_advancement_id >= MissionAdvancementPossibilities.SUBMITTED &&
  !isDeliverableCancelled(deliverable);

export const isEveryDeliverablesSubmissionCancellable = (deliverables: OrderWorkunit[]) =>
  deliverables.every(isDeliverableSubmitted) && !deliverables.every(isDeliverableCancelled);

export const isEveryDeliverablesValidationCancellable = (deliverables: OrderWorkunit[]) =>
  deliverables.every(isDeliverableValidated) &&
  !deliverables.every(isDeliverableCancelled) &&
  !isEveryDeliverableAccepted(deliverables);

export const isDeliverableCancelled = (deliverable: OrderWorkunit) => deliverable?.label?.includes('cancelled');

export const isEveryDeliverableCancelled = (deliverables: OrderWorkunit[]) =>
  deliverables.every(isDeliverableCancelled);

export const isDeliverableSubmitted = (deliverable: OrderWorkunit) =>
  deliverable?.mission_advancement_id &&
  deliverable?.mission_advancement_id === MissionAdvancementPossibilities.SUBMITTED &&
  !isDeliverableCancelled(deliverable);

export const isDeliverableValidadable = (deliverable: OrderWorkunit) =>
  deliverable?.mission_advancement_id &&
  deliverable?.mission_advancement_id === MissionAdvancementPossibilities.SUBMITTED &&
  !isDeliverableCancelled(deliverable);

export const isEveryDeliverableValidadable = (deliverables: OrderWorkunit[]) =>
  deliverables.every(isDeliverableValidadable);

export const isEveryDeliverableSubmitted = (deliverables: OrderWorkunit[]) =>
  deliverables.every(isDeliverableSubmitted);

export const isAdvancementOkForCancelOrDeleteByManagers = (deliverable: OrderWorkunit) =>
  !deliverable.mission_advancement_id ||
  deliverable.mission_advancement_id <= MissionAdvancementPossibilities.REFUSED_TL;

export const isEveryDeliverableCancellableByManagers = (deliverables: OrderWorkunit[]) =>
  deliverables.every(
    (deliverable) =>
      isAdvancementOkForCancelOrDeleteByManagers(deliverable) &&
      !isDeliverableCancelled(deliverable) &&
      isDeliverableFromQuote(deliverable)
  );

export const isEveryDeliverableDeletableByManagers = (deliverables: OrderWorkunit[]) =>
  deliverables.every(
    (deliverable) =>
      isAdvancementOkForCancelOrDeleteByManagers(deliverable) &&
      !isDeliverableCancelled(deliverable) &&
      !isDeliverableFromQuote(deliverable)
  );

export const isEveryDeliverableCancellableByConsultant = (deliverables: OrderWorkunit[]) =>
  deliverables.every(
    (deliverable) =>
      !deliverable.mission_advancement_id && !isDeliverableCancelled(deliverable) && isDeliverableFromQuote(deliverable)
  );

export const isEveryDeliverableDeletableByConsultants = (deliverables: OrderWorkunit[]) =>
  deliverables.every((deliverable) => !isDeliverableSubmitted(deliverable)) &&
  !isEveryDeliverableFromQuote(deliverables);

export const isDeliverableAccepted = (deliverable: OrderWorkunit) =>
  deliverable.mission_advancement_id &&
  deliverable.mission_advancement_id > MissionAdvancementPossibilities.ACCEPTATION_TL;

export const isEveryDeliverableAccepted = (deliverables: OrderWorkunit[]) => deliverables.every(isDeliverableAccepted);

export const canRateDeliverables = (deliverables: OrderWorkunit[]) =>
  deliverables.every(
    (deliverable) =>
      !deliverable.rating &&
      deliverable.mission_advancement_id &&
      [
        MissionAdvancementPossibilities.REFUSED_CLIENT,
        MissionAdvancementPossibilities.RESERVE_CLIENT,
        MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
      ].includes(deliverable.mission_advancement_id)
  );

export const isEveryDeliverablesRated = (deliverables: OrderWorkunit[]) =>
  deliverables.every((deliverable) => deliverable.rating);

export const isEveryDeliverablesUnrated = (deliverables: OrderWorkunit[]) =>
  deliverables.every((deliverable) => !deliverable.rating);

export const canReviewDeliverables = (deliverables: OrderWorkunit[]) =>
  deliverables.every(
    (deliverable) =>
      deliverable.rating &&
      deliverable.mission_advancement_id &&
      [
        MissionAdvancementPossibilities.REFUSED_CLIENT,
        MissionAdvancementPossibilities.RESERVE_CLIENT,
        MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
      ].includes(deliverable.mission_advancement_id)
  );

export const canManagersEditDeliverables = (deliverables: OrderWorkunit[]) =>
  deliverables.every(
    (deliverable) =>
      ((deliverable.mission_advancement_id &&
        deliverable.mission_advancement_id < MissionAdvancementPossibilities.RESERVE_TL) ||
        !deliverable.mission_advancement_id) &&
      !isDeliverableCancelled(deliverable)
  );

export const canCancelCommentDeliverables = (deliverables: OrderWorkunit[]) =>
  !deliverables.every(
    (deliverable) =>
      !deliverable['mission-comments'] ||
      deliverable['mission-comments'].every(
        (comment) =>
          !comment.mission_advancement_id ||
          !(
            comment.mission_advancement_id &&
            [
              MissionAdvancementPossibilities.REFUSED_CLIENT,
              MissionAdvancementPossibilities.RESERVE_CLIENT,
              MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
            ].includes(comment.mission_advancement_id)
          )
      )
  );

export const canCancelAcceptanceDeliverables = (deliverables: OrderWorkunit[]) =>
  !deliverables.every(
    (deliverable) =>
      !deliverable.mission_advancement_id ||
      (deliverable.mission_advancement_id &&
        ![
          MissionAdvancementPossibilities.REFUSED_CLIENT,
          MissionAdvancementPossibilities.RESERVE_CLIENT,
          MissionAdvancementPossibilities.ACCEPTATION_CLIENT,
        ].includes(deliverable.mission_advancement_id))
  );
export const isEveryDeliverablesRefusedByClient = (deliverables: OrderWorkunit[]) =>
  deliverables.every(
    (deliverable) =>
      deliverable.mission_advancement_id &&
      deliverable.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_CLIENT
  );
