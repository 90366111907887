import {
  Box,
  Checkbox,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import DeliverableSheetApiService from 'api/DeliverableSheetApiService';
import OrderApiService from 'api/OrderApiService';
import orderWorkunitApiService from 'api/OrderWorkunitApiService';
import { Loader } from 'components';
import ModalCustom from 'components/Modal/Modal';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import { filter, flatten, forEach, isEqual, map, uniq, uniqWith, values } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  cancelSelectByHand,
  changeTabAction,
  selectDeliverablesAction,
  startSelectByHand,
} from 'store/actions/deliverableActions';
import { createDeliverableSheetAction } from 'store/actions/deliverableSheetActions';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import DeliverableSheet from 'types/entities/DeliverableSheet';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import { DeliverableReportTab } from 'types/enums/DeliverableReportTab';
import { DeliverableTabPage } from 'types/enums/DeliverableTabPage';
import { TabSheet } from '../../../Sheet/SheetContext/SheetContext.helper';
// import './DeliverableReportsCreateReportModal.scss';
import DeliverableReportsCreateReportModalFormLabels from './DeliverableReportsCreateReportModalFormLabels/DeliverableReportsCreateReportModalFormLabels';

type MonthType = { month: string; start_date: string; end_date: string };

type TFilterParams = {
  scopes: Scope[];
  consultants: User[];
  clients: {
    id: number;
    name: string;
    workunits: number;
  }[];
};

const DeliverableReportsCreateReportModal: React.FC = () => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const history = useHistory();
  const open = useSelector((state) => state.dialogs.deliverableCreateReport);
  const close = () => dispatch(closeDialogAction('deliverableCreateReport'));
  const selectedDeliverables = useSelector((state) => state.deliverable.selectedDeliverablesModel);
  const [clients, setClients] = useState<User[]>([]);
  const appState = useSelector((state) => state.app);
  const userRoles = useUserRoles();
  const deliverableState = useSelector((state) => state.deliverable);
  const reportState = useSelector((state) => state.deliverableSheet);
  const [isTouchedByUser, setIsTouchedByUser] = useState(false);
  const { makeCall } = useApi();
  const [filterParams, setFiltersParams] = useState<TFilterParams>({
    scopes: [],
    consultants: [],
    clients: [],
  });
  const [isModalConfirmDisabled, setIsModalConfirmDisabled] = useState(true);
  const [months, setMonths] = useState<MonthType[]>([]);
  const [isModalCancelOpen, setIsModalCancelOpen] = useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);

  type InitialBodyStateType = {
    scopes_ids: Set<number>;
    title: string;
    all_production_clients_ids: Set<number>;
    assigned_production_clients_ids: Set<number>;
    dates: Set<string>;
  };
  const initialBodyState: InitialBodyStateType = {
    scopes_ids: new Set<number>(),
    title: '',
    all_production_clients_ids: new Set<number>(),
    assigned_production_clients_ids: new Set<number>(),
    dates: new Set<string>(),
  };
  const [body, setBody] = useState<{
    title: string;
    scopes_ids: Set<number>;
    all_production_clients_ids: Set<number>;
    assigned_production_clients_ids: Set<number>;
    dates: Set<string>;
  }>(initialBodyState);

  const getSortedDates = useCallback(
    () =>
      Array.from(body.dates)
        .map((x) => new Date(x))
        .sort((a, b) => a.getTime() - b.getTime()),
    [body.dates, body.dates.size]
  );

  useEffect(() => {
    const getParamsFilters = async () => {
      if (!open) return;

      const data = await makeCall(OrderApiService.fetchParamsFilterDeliverable(deliverableState.order?.id as number));
      if (data) {
        if (userRoles.isTeamLeader) {
          setFiltersParams((prevFilterParams) => ({
            ...prevFilterParams,
            scopes: deliverableState.order_scopes
              .filter((os) => os.scope_name === 'General' || os.accountable_id === (appState.user as User).id)
              .map((s) => s.scope as Scope),
          }));
        } else {
          const { scopes, consultants, clients } = data;
          setClients(() => clients);
          setFiltersParams((prevFilterParams) => ({ ...prevFilterParams, scopes, consultants }));
        }
      }
    };

    if (deliverableState.order?.id) {
      getParamsFilters();
      setMissionsMonths();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverableState.order?.id, open]);

  const areMonthsConsecutive = useCallback((): boolean => {
    const dates = getSortedDates();
    if (dates.length <= 1) {
      // If there are 0 or 1 date, they are considered consecutive by default
      return true;
    }

    const sortedDates = [...dates].sort((a, b) => a.getTime() - b.getTime());
    for (let i = 1; i < sortedDates.length; i += 1) {
      const currentMonth = sortedDates[i].getMonth();
      const previousMonth = sortedDates[i - 1].getMonth();
      const currentYear = sortedDates[i].getFullYear();
      const previousYear = sortedDates[i - 1].getFullYear();

      // Check if the current date is not consecutive with the previous date
      if (currentYear > previousYear || (currentYear === previousYear && currentMonth !== previousMonth + 1)) {
        return false;
      }
    }

    return true;
  }, [getSortedDates]);
  function isSetsEqual(setA: Set<string | number>, setB: Set<string | number>): boolean {
    if (setA.size !== setB.size) return false;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of setA) {
      if (!setB.has(item)) return false;
    }

    return true;
  }
  const handleClose = () => {
    const isClosingDisabled = () => {
      if (selectedDeliverables.length) return true;
      return Object.entries(body).some(([key, value]) => {
        const initialBodyValue = initialBodyState[key as keyof InitialBodyStateType];

        if (initialBodyValue instanceof Set && value instanceof Set) {
          return !isSetsEqual(value, initialBodyValue);
        }
        return value !== initialBodyValue;
      });
    };

    if (isClosingDisabled() && !isModalCancelOpen) {
      setIsModalCancelOpen(true);
      return;
    }

    dispatch(cancelSelectByHand());
    setBody(initialBodyState);
    setIsTouchedByUser(false);
    close();
  };

  function isClientData(value: unknown): value is { name: string; workunits: number } {
    return (
      typeof value === 'object' &&
      value !== null &&
      typeof (value as { name?: unknown; workunits?: unknown }).name === 'string' &&
      typeof (value as { name?: unknown; workunits?: unknown }).workunits === 'number'
    );
  }

  function isNotEmptyObject(obj: Record<string, unknown>): boolean {
    return Object.keys(obj).length > 0;
  }

  const getExpectedDates = () => {
    const sortedDates = getSortedDates();
    const minDate = moment.min([...body.dates].map((date) => moment(date)));
    const maxDate = moment.max([...body.dates].map((date) => moment(date)));
    if (areMonthsConsecutive()) {
      return [
        {
          start: `${minDate.startOf('M').format('YYYY-MM-DD')}`,
          end: `${maxDate.endOf('M').format('YYYY-MM-DD')}`,
        },
      ];
    }

    return sortedDates.map((date) => ({
      start: `${moment(date).startOf('M').format('YYYY-MM-DD')}`,
      end: `${moment(date).endOf('M').format('YYYY-MM-DD')}`,
    }));
  };

  useEffect(() => {
    const getClientsByFilters = async () => {
      if (!open) return;
      let data: any;
      if (selectedDeliverables.length) {
        data = await makeCall(
          orderWorkunitApiService.getAllOrderWorkUnitByOrderId(deliverableState.order?.id as number, {
            id: (selectedDeliverables as unknown as OrderWorkunit['id'][]).join(','),
            attr: ['client_id', 'id', 'client', 'client_fullname', 'forecast_date', 'delivery_date'].join('.'),
          }),
          '',
          'fetch_client_create_report'
        );
        const clients: { id: number; name: string; workunits: number }[] = [
          {
            id: deliverableState.order?.client?.id as number,
            name: `${deliverableState.order?.client?.last_name} ${deliverableState.order?.client?.first_name}` as string,
            workunits: 0,
          },
        ];
        const indexOfPrincipalClient = clients.findIndex((client) => client.id === deliverableState.order?.client?.id);
        data.forEach((x: OrderWorkunit) => {
          if (!x.client_id) {
            clients[indexOfPrincipalClient].workunits += 1;
            return;
          }
          const indexClient = clients.findIndex((client) => client.id === x.client_id);
          if (indexClient > -1) {
            clients[indexClient].workunits += 1;
            return;
          }
          clients.push({ id: x.client_id as number, name: x.client_fullname, workunits: 1 });
        });
        setFiltersParams((params) => ({
          ...params,
          clients,
        }));
      } else if (body.scopes_ids.size > 0 && body.dates.size > 0) {
        data = await makeCall(
          orderWorkunitApiService.getClientOrderWorkunitsByDateAndScope(deliverableState.order?.id as number, {
            scope_ids: [...body.scopes_ids],
            dates: getExpectedDates(),
          }),
          '',
          'fetch_client_create_report'
        );
        if (data?.data && typeof data?.data === 'object' && data !== null && isNotEmptyObject(data?.data)) {
          setFiltersParams((params) => ({
            ...params,
            clients: Object.entries(data.data).map(([id, value]) => {
              if (isClientData(value)) {
                return {
                  id: parseInt(id, 10),
                  name: value.name,
                  workunits: value.workunits,
                };
              }
              // Handle the case when 'value' is not in the expected format
              // Return a default value or throw an error depending on your requirement
              return {
                id: parseInt(id, 10),
                name: '',
                workunits: 0,
              };
            }),
          }));
        }
      }
    };
    getClientsByFilters();
  }, [
    body.scopes_ids.size,
    body.dates.size,
    makeCall,
    selectedDeliverables,
    deliverableState.order?.id,
    open,
    deliverableState.order?.client,
    areMonthsConsecutive,
    body.scopes_ids,
    body.dates,
    getSortedDates,
  ]);

  const submit = async () => {
    const promisesCreateReport: Promise<DeliverableSheet[]>[] = [];
    if (body.all_production_clients_ids.size > 0) {
      Array.from(body.all_production_clients_ids).forEach((x) => {
        const generateBodyReport = (clientId: number) => {
          const item: any = {
            title: body.title,
            all_production_clients_ids: [clientId],
            type: reportState.currentReportTab.toLocaleLowerCase(),
          };
          if (selectedDeliverables.length) {
            item.order_workunit_ids = selectedDeliverables;
          } else {
            if (body.scopes_ids.size !== 0) {
              item.scope_ids = [...body.scopes_ids];
            } else if (body.scopes_ids.size === 0) {
              item.scope_ids = filterParams.scopes.map((scope) => scope.id);
            }
            const dates = flatten(map(getExpectedDates(), (date) => values(date)));
            if (dates.length) {
              item.start_date = moment
                .min([...dates].map((date) => moment(date)))
                .format('YYYY-MM-DD')
                .toString();
              item.end_date = moment
                .max([...dates].map((date) => moment(date)))
                .format('YYYY-MM-DD')
                .toString();
            }
          }
          return item;
        };
        const generateTechnicalReport = (x: number) =>
          DeliverableSheetApiService.createTechnicalReport(deliverableState.order?.id as number, generateBodyReport(x));
        promisesCreateReport.push(generateTechnicalReport(x));
      });
    }
    if (body.assigned_production_clients_ids.size > 0) {
      Array.from(body.assigned_production_clients_ids).forEach((x) => {
        const generatePromiseBodyReport = (x: User['id']) => {
          const item: any = {
            title: body.title,
            assigned_production_clients_ids: [x],
            type: reportState.currentReportTab.toLocaleLowerCase(),
          };
          if (selectedDeliverables.length) {
            item.order_workunit_ids = selectedDeliverables;
          } else {
            if (body.scopes_ids.size !== 0) {
              item.scope_ids = [...body.scopes_ids];
            }
            const dates = flatten(map(getExpectedDates(), (date) => values(date)));
            if (dates.length) {
              item.start_date = moment
                .min([...dates].map((date) => moment(date)))
                .format('YYYY-MM-DD')
                .toString();
              item.end_date = moment
                .max([...dates].map((date) => moment(date)))
                .format('YYYY-MM-DD')
                .toString();
            }
          }
          return item;
        };

        const generateTechnicalReport = (x: number) =>
          DeliverableSheetApiService.createTechnicalReport(
            deliverableState.order?.id as number,
            generatePromiseBodyReport(x)
          );
        promisesCreateReport.push(generateTechnicalReport(x));
      });
    }
    const resolvedPromises = ([] as DeliverableSheet[]).concat(...(await Promise.all(promisesCreateReport)));
    if (resolvedPromises.length) {
      resolvedPromises.forEach((deliverableSheet) => {
        const addedDeliverableSheet = {
          ...deliverableSheet,
          client: clients?.find((x) => x.id === deliverableSheet.client_id),
        };
        dispatch(createDeliverableSheetAction(addedDeliverableSheet));
      });
    }
    const pdfGeneration = resolvedPromises.map((x) => DeliverableSheetApiService.generatePdf(x.id));

    await Promise.all(pdfGeneration);
    dispatch(selectDeliverablesAction([]));
    dispatch(cancelSelectByHand());
    setBody(initialBodyState);
    if (pdfGeneration.length === 1 && resolvedPromises.length) {
      const report = resolvedPromises[0];
      const reportTypeUrl = report.type === 'technical' ? 'deliverablesheet' : 'financialreport';
      const redirect = `/${appState?.customer?.slug}/${reportTypeUrl}/${deliverableState?.order?.id}/${report.id}`;
      history.push(redirect, { tab: TabSheet.PREVIEW });
    }
    close();
    handleModalConfirmClose();
  };

  useEffect(() => {
    if (deliverableState.order?.id) {
      setMissionsMonths();
    }
    setIsModalConfirmDisabled(
      body.title === '' ||
        !!(body.all_production_clients_ids.size === 0 && body.assigned_production_clients_ids.size === 0)
    );
  }, [body]);

  async function setMissionsMonths() {
    const scopeIds = body.scopes_ids;
    const res = await makeCall(
      orderWorkunitApiService.getOrderWorkunitByOrderId(
        deliverableState.order?.id as number,
        ['forecast_date', 'client_id', 'scope_id'],
        {
          size: '-1',
        }
      )
    );
    const deliverables: { client_id: number; forecast_date: string; scope_id: number }[] = res.datas;
    const validDeliverables = filter(
      deliverables,
      (deliverable) => scopeIds.has(deliverable.scope_id)
    );
    if (validDeliverables) {
      const dates = uniq(flatten(map(validDeliverables, (deliverable) => deliverable.forecast_date)));
      dates.sort((a, b) => moment(a).diff(moment(b)));
      const months: MonthType[] = [];
      forEach(dates, (date) => {
        const momentDate = moment(date);
        months.push({
          month: momentDate.format('MMMM-YYYY'),
          start_date: momentDate.startOf('month').format('YYYY-MM-DD'),
          end_date: momentDate.endOf('month').format('YYYY-MM-DD'),
        });
      });
      setMonths(uniqWith(months, isEqual));
    }
  }

  function handleDateChange(
    month: MonthType
  ): ((event: React.SyntheticEvent<Element, Event>, checked: boolean) => void) | undefined {
    return (e, checked) => {
      if (checked) {
        setBody((prevBody) => ({
          ...prevBody,
          dates: prevBody.dates.add(month.end_date),
        }));
      } else {
        setBody((prevBody) => {
          const filteredEndDates = new Set(prevBody.dates);
          filteredEndDates.delete(month.end_date);

          return {
            ...prevBody,
            dates: filteredEndDates,
          };
        });
      }
    };
  }

  const generateTechnicalReportTitle = useCallback(() => {
    const sortedDates = getSortedDates();
    const typeOfSheet = reportState.currentReportTab === DeliverableReportTab.TECHNICAL ? 'Technical' : 'Financial';

    if (!sortedDates.length) {
      return '';
    }

    if (sortedDates.length === 1) {
      const startMonth = getMonthFromString(sortedDates[0]);
      const startYear = sortedDates[0].getFullYear();
      return `${typeOfSheet} report of ${startMonth} ${startYear}`;
    }

    const firstDate = sortedDates[0];
    const lastDate = sortedDates[sortedDates.length - 1];

    const startMonth = getMonthFromString(firstDate);
    const startYear = firstDate.getFullYear();
    const endMonth = getMonthFromString(lastDate);
    const endYear = lastDate.getFullYear();

    const consecutiveMonths: boolean = areMonthsConsecutive();
    if (consecutiveMonths) {
      return `${typeOfSheet} report from ${startMonth} ${startYear} to ${endMonth} ${endYear}`;
    }

    const monthsYears = sortedDates.map((date) => {
      const month = getMonthFromString(date);
      const year = date.getFullYear();
      return `${month} ${year}`;
    });

    return `${typeOfSheet} report of ${monthsYears.join('- ')}`;
  }, [areMonthsConsecutive, getSortedDates]);

  useEffect(() => {
    if (!isTouchedByUser) {
      const title = generateTechnicalReportTitle();
      setBody((prevBody) => ({
        ...prevBody,
        title,
      }));
    }
  }, [generateTechnicalReportTitle, isTouchedByUser]);

  function getMonthFromString(dateString: Date): string {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', { month: 'long' });
  }

  const goSelectDeliverableByHands = () => {
    setBody((prevBody) => ({ ...prevBody, scopes_ids: new Set(), dates: new Set() }));
    dispatch(changeTabAction(DeliverableTabPage.PRODUCTION));
    dispatch(startSelectByHand());
    close();
  };

  const handleModalCancelClose = () => {
    setIsModalCancelOpen(false);
  };
  const handleModalCancelConfirm = () => {
    setIsModalCancelOpen(false);
    handleClose();
  };

  const handleModalConfirmOpen = () => {
    setIsModalConfirmOpen(true);
  };

  const handleModalConfirmClose = () => {
    setIsModalConfirmOpen(false);
  };

  const dialogConfirmBody = (
    <DialogContent>
      <DialogContentText>
        <Typography>{t('Are you sure you want to create a report ?')}</Typography>
      </DialogContentText>
    </DialogContent>
  );
  const dialogCancelBody = (
    <DialogContent>
      <DialogContentText>
        <Typography>{t('You will loose all your progress')}</Typography>
      </DialogContentText>
    </DialogContent>
  );
  const titleModal = useCallback(
    () =>
      t(`Create ${reportState.currentReportTab === DeliverableReportTab.TECHNICAL ? 'technical' : 'financial'} report`),
    [t, reportState.currentReportTab]
  );

  const dialogBody = (
    <DialogContent>
      <Box sx={{ mb: 3 }}>
        <InputLabel htmlFor="create_report_modal-description-content">
          {t(
            `Enter a title for your ${
              reportState.currentReportTab === DeliverableReportTab.TECHNICAL ? 'technical' : 'financial'
            } monitoring report`
          )}
        </InputLabel>
        <TextField
          id="create_report_modal-description-content"
          placeholder={t('Your title')}
          value={body.title}
          onChange={(e) => {
            setIsTouchedByUser(true);
            setBody((prevBody) => ({ ...prevBody, title: e.target.value }));
          }}
          fullWidth
        />
      </Box>
      {!selectedDeliverables?.length ? (
        <Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel>{t('Select scope(s) you want in your report')}</InputLabel>
            <FormGroup>
              {filterParams.scopes.map((scope) => (
                <DeliverableReportsCreateReportModalFormLabels
                  body={body}
                  item={scope}
                  targetParam="scopes_ids"
                  setBody={setBody}
                />
              ))}
            </FormGroup>
          </Box>
          <Box sx={{ mb: 3 }}>
            <InputLabel>{t('Select production you want in your report')}</InputLabel>
            {months.length === 0 && body.scopes_ids.size > 0 ? (
              <Box sx={{ mb: 3 }}>No date found for this scope</Box>
            ) : (
              <FormGroup sx={{ mb: 1 }}>
                {months.map((month) => (
                  <FormControlLabel
                    checked={body.dates.has(month.end_date)}
                    control={<Checkbox />}
                    label={<span>{month.month}</span>}
                    className="checkbox"
                    onChange={handleDateChange(month)}
                  />
                ))}
              </FormGroup>
            )}
            <Box sx={{ display: 'flex' }}>
              Or&nbsp;
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={goSelectDeliverableByHands}
                onKeyDown={goSelectDeliverableByHands}
                tabIndex={0}
              >
                select deliverables by hand
              </Link>
            </Box>
          </Box>
        </Box>
      ) : (
        <Grid container xl={12} spacing={2} mb={4}>
          <Grid item xl={12}>
            <InputLabel>{t('Deliverable selected')}</InputLabel>
          </Grid>
          <Grid item xl={12}>
            <Box>{selectedDeliverables.length} deliverables selected</Box>
          </Grid>
        </Grid>
      )}
      <DeliverableReportCreateModalClients
        clients={filterParams.clients}
        setBody={setBody}
        selectedDeliverables={selectedDeliverables}
        body={body}
      />
    </DialogContent>
  );

  return (
    <>
      <ModalCustom
        open={open}
        modalName="deliverableCreateReport"
        onClose={handleClose}
        body={dialogBody}
        onConfirm={handleModalConfirmOpen}
        confirmText={t('Create report')}
        cancelText={t('Cancel')}
        disabledConfirm={isModalConfirmDisabled}
        textConfirmDisable={t('Missing required fields')}
        titleText={titleModal()}
        onCloseEvent={() => handleClose()}
        confirmLoad
      />
      <ModalCustom
        open={isModalConfirmOpen}
        modalName="deliverableCreateReportConfirm"
        onClose={handleModalConfirmClose}
        body={dialogConfirmBody}
        onConfirm={() => submit()}
        confirmText={t('Confirm')}
        cancelText={t('Cancel')}
        titleText={t('Confirm report creation')}
        sx={{ zIndex: 9999 }}
        size="sm"
        confirmLoad
      />
      <ModalCustom
        open={isModalCancelOpen}
        modalName="deliverableCreateReportConfirmCancel"
        onClose={handleModalCancelClose}
        onCloseEvent={handleModalCancelClose}
        body={dialogCancelBody}
        onConfirm={() => handleModalCancelConfirm()}
        confirmText={t('Close')}
        cancelText={t('Cancel')}
        titleText={t('Cancel report creation')}
        sx={{ zIndex: 9999 }}
        size="sm"
      />
    </>
  );
};

export default DeliverableReportsCreateReportModal;

const DeliverableReportCreateModalClients = (props: {
  clients: any;
  setBody: any;
  selectedDeliverables: any;
  body: any;
}) => {
  const [t] = useTranslation();
  const loadings = useSelector((state) => state.loadings);
  const { setBody, selectedDeliverables, body, clients } = props;
  if (loadings.fetch_client_create_report && loadings.fetch_client_create_report > 0) {
    return <Loader />;
  }

  if (selectedDeliverables.length > 0 || (body.scopes_ids.size > 0 && (body.dates.size > 0 || body.dates.size > 0))) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Select clients who will only receive deliverables assigned to them')}</InputLabel>
          <FormGroup>
            {filter(clients, (client: { client: User; workunits: number }) => client.workunits > 0).map(
              (client: { client: User; workunits: number }) => (
                <DeliverableReportsCreateReportModalFormLabels
                  body={body}
                  item={client}
                  targetParam="assigned_production_clients_ids"
                  setBody={setBody}
                />
              )
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Or select client who will reveive all deliverables of the production')}</InputLabel>
          <FormGroup>
            {clients.map((client: { client: User; workunits: number }) => (
              <DeliverableReportsCreateReportModalFormLabels
                body={body}
                item={client}
                targetParam="all_production_clients_ids"
                setBody={setBody}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    );
  }
  return <Box>Select at least one scope and one date or select deliverables by hand to display clients</Box>;
};
