import { Alert, IconButton, LinearProgress, Snackbar } from '@mui/material';
import { UndoIcon } from 'components/CustomIcons';
import { NotAffilied } from 'features';
import LoginCallback from 'features/LoginCallback/LoginCallback';
import useCancelApi from 'hooks/useCancelApi';
import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useAppState } from 'store/Provider';
import { RouteType } from 'types/RouteType';
import TermsOfUse from '../features/TermsOfUse/TermsOfUse';
import PrivateRoute from './PrivateRoutes';

type RouterProps = {
  appIsReady: boolean;
  roles: string[] | undefined;
  availableRoutes: RouteType[] | undefined;
  activeSlug: string;
  progress: React.MutableRefObject<number>;
  locationForRedirect: boolean;
};

const AppRouter = (props: RouterProps) => {
  const { appIsReady, roles, availableRoutes, activeSlug, progress, locationForRedirect } = props;
  const { state, dispatch } = useAppState();
  const { app, snackbar } = state;
  const history = useHistory();
  const { cancelRequests } = useCancelApi();
  const [t] = useTranslation();
  const previousPath = useRef('');

  useLayoutEffect(() => {
    if (!previousPath.current) previousPath.current = history.location.pathname;
    history.listen(async (loc) => {
      if (loc.pathname !== previousPath.current) {
        cancelRequests(previousPath.current);
        previousPath.current = loc.pathname;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {snackbar && snackbar?.open && (
        <Snackbar
          open={snackbar?.open}
          autoHideDuration={snackbar?.duration ?? 2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={(e, reason) => {
            if (reason === 'timeout')
              dispatch(
                setSnackbarAction({
                  open: false,
                  message: '',
                  severity: snackbar?.severity,
                  duration: 0,
                  undoCallback: async () => Promise.resolve(),
                })
              );
          }}
        >
          <span>
            <Alert
              severity={snackbar.severity ?? 'info'}
              className={snackbar?.undo ? 'with_undo' : ''}
              style={
                snackbar && snackbar?.undo
                  ? {
                      width: snackbar?.undo ? '25vw' : '15vw',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: 'white',
                      paddingBottom: snackbar?.undo ? 0 : '1rem',
                    }
                  : {}
              }
            >
              <span className={`${snackbar?.undo ? 'very_large' : ''}`}>
                {snackbar?.message}
                {snackbar?.undo && snackbar?.undoCallback && (
                  <IconButton
                    onClick={async () => {
                      dispatch(setSnackbarAction({ open: false, message: '', severity: snackbar?.severity }));
                      if (snackbar?.undoCallback) await snackbar.undoCallback();
                    }}
                    size="large"
                  >
                    <UndoIcon />
                    <span>{t('Cancel')}</span>
                  </IconButton>
                )}{' '}
              </span>
            </Alert>
            {snackbar?.undo && snackbar?.undoCallback && (
              <LinearProgress variant="determinate" value={progress.current} className={`${snackbar?.severity}`} />
            )}
          </span>
        </Snackbar>
      )}
      <Switch>
        {appIsReady && (
          <Route exact path="/terms-of-use">
            <TermsOfUse />
          </Route>
        )}
        {appIsReady && app.acceptedTermsOfuse === false && <Redirect to="/terms-of-use" />}
        {!appIsReady && <Route path="/callback" component={LoginCallback} />}
        {appIsReady &&
          roles &&
          availableRoutes &&
          availableRoutes.length > 1 &&
          PrivateRoute(availableRoutes, activeSlug, roles)}
        {/* {((!activeCustomer?.id && !isUserOnATenant && availableRoutes && availableRoutes?.length === 1) ||
              isUserLost) && (
              <Route
                path={`${availableRoutes?.[0].path}`}
                component={availableRoutes?.[0].Component}
                render={() => <Redirect from="*" to="/" />}
              />
            )} */}
        {activeSlug && !locationForRedirect && <Route render={() => <Redirect to={`/${activeSlug}/dashboard`} />} />}
        <Route path="*" component={NotAffilied} />
      </Switch>
    </>
  );
};

export default AppRouter;
