import { DialogsActions } from '../actions/dialogsActions';
import { RootActions } from '../redux';
import { dialogsState, DialogsState } from '../states/dialogsState';

const dialogsReducer = (state = dialogsState, action: RootActions): DialogsState => {
  switch (action.type) {
    case DialogsActions.OPEN_DIALOG:
      return {
        ...state,
        [action.payload.name]: true,
        data: action.payload.data,
      };
    case DialogsActions.CLOSE_DIALOG:
      return handleCloseDialog(state, action);
    default:
      return state;
  }
};

export default dialogsReducer;
function handleCloseDialog(state: DialogsState, action: { type: 'CLOSE_DIALOG'; payload: string }) {
  const { data, ...rest } = state;
  return {
    ...rest,
    [action.payload]: false,
  };
}
