import useManagementRights from 'hooks/rights/useManagementRights';
import React from 'react';
import { useAppState } from 'store/Provider';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import ManagementTabs from './ManagementTabs/ManagementTabs';

type ManagementHeadBandProps = {
  currentToAssignCount: number;
};

const ManagementHeadband = (props: ManagementHeadBandProps) => {
  const { currentToAssignCount } = props;
  const { state } = useAppState();
  const { canShowTabsOrder } = useManagementRights();
  return state.management.pageHandler.currentTab !== OrdersTabState.ARCHIVED && canShowTabsOrder ? (
    <ManagementTabs currentToAssignCount={currentToAssignCount} />
  ) : (
    <></>
  );
};

export default ManagementHeadband;
