import { reduce } from 'lodash';
import { FilterState } from '../states/filterState';

export const clearSelectedFilters = (selected: FilterState['selected']) =>
  reduce(
    selected,
    (result: FilterState['selected'], value, key) => {
      result[key] = [];
      return result;
    },
    {}
  );
