import { RoleType } from 'types/RoleType';

export const PERMISSIONS = {
  SEE_PRICE_DASHBOARD: 'FETCH_ALL_DEVISE_FOR_CATALOG',
  FETCH_ALL_DEVISE_FOR_CATALOG: 'FETCH_ALL_DEVISE_FOR_CATALOG',
  CREATE_CATALOG: 'CREATE_CATALOG',
  EDIT_CLIENT: 'EDIT_CLIENT',
  ADD_DELIVERABLE: 'ADD_DELIVERABLE',
  DUPLICATE_DELIVERABLE: 'DUPLICATE_DELIVERABLE',
  USE_QUICK_ACTION_DELIVERABLE: 'USE_QUICK_ACTION_DELIVERABLE',
  EXPORT_DELIVERABLE: 'EXPORT_DELIVERABLE',
  EXPORT_GR_DELIVERABLE: 'EXPORT_GR_DELIVERABLE',
  CREATE_DELIVERABLE_SHEET: 'CREATE_DELIVERABLE_SHEET',
  SUBMIT_DELIVERABLE: 'SUBMIT_DELIVERABLE',
  ACCEPT_DELIVERABLE: 'ACCEPT_DELIVERABLE',
  VALIDATE_DELIVERABLE: 'VALIDATE_DELIVERABLE',
  MANAGER_ACCEPT_DELIVERABLE: 'MANAGER_ACCEPT_DELIVERABLE',
  CANCEL_DELIVERABLE: 'CANCEL_DELIVERABLE',
  CANCEL_SUBMISSION_DELIVERABLE: 'CANCEL_SUBMISSION_DELIVERABLE',
  CANCEL_ACCEPTANCE_DELIVERABLE: 'CANCEL_ACCEPTANCE_DELIVERABLE',
  CANCEL_VALIDATION_DELIVERABLE: 'CANCEL_VALIDATION_DELIVERABLE',
  CANCEL_RATING_DELIVERABLE: 'CANCEL_RATING_DELIVERABLE',
  CANCEL_COMMENT_DELIVERABLE: 'CANCEL_COMMENT_DELIVERABLE',
  EDIT_DELIVERABLE: 'EDIT_DELIVERABLE',
  DELETE_DELIVERABLE: 'DELETE_DELIVERABLE',
  RESTORE_DELIVERABLE: 'RESTORE_DELIVERABLE',
  RATE_DELIVERABLE: 'RATE_DELIVERABLE',
  MANAGER_RATE_DELIVERABLE: 'MANAGER_RATE_DELIVERABLE',
  REVIEW_DELIVERABLE: 'REVIEW_DELIVERABLE',
  ARCHIVE_ORDER_MANAGEMENT: 'ARCHIVE_ORDER_MANAGEMENT',
  ARCHIVE_ORDER_QUOTE: 'ARCHIVE_ORDER_QUOTE',
  RESTORE_ORDER_QUOTE: 'RESTORE_ORDER_QUOTE',
  RESTORE_ORDER_MANAGEMENT: 'RESTORE_ORDER_MANAGEMENT',
  CREATE_REPORT: 'CREATE_REPORT',
  CREATE_ORDER: 'CREATE_ORDER',
  EDIT_ORDER: 'EDIT_ORDER',
  CREATE_WORKUNIT_ORDER: 'CREATE_WORKUNIT_ORDER',
  SHOW_SUMMARY_ORDER: 'SHOW_SUMMARY_ORDER',
  AFFECTATION_ORDER: 'AFFECTATION_ORDER',
  DUPLICATE_ORDER: 'DUPLICATE_ORDER',
  DOWNLOAD_ORDER: 'DOWNLOAD_ORDER',
  CLOSE_ORDER: 'CLOSE_ORDER',
  OPEN_ORDER: 'OPEN_ORDER',
  SHOW_STAT_ORDER: 'SHOW_STAT_ORDER',
  SHOW_GRAPH_ORDER: 'SHOW_GRAPH_ORDER',
  SHOW_KPI_ORDER: 'SHOW_KPI_ORDER',
  SHOW_TABS_ORDER: 'SHOW_TABS_ORDER',
};

/**
 * associative list of actions allowed by role
 */
export const getPermissions: { [key in RoleType]: string[] } = {
  admin: [
    PERMISSIONS.SEE_PRICE_DASHBOARD,
    PERMISSIONS.FETCH_ALL_DEVISE_FOR_CATALOG,
    PERMISSIONS.CREATE_CATALOG,
    PERMISSIONS.EDIT_CLIENT,
    PERMISSIONS.ADD_DELIVERABLE,
    PERMISSIONS.DUPLICATE_DELIVERABLE,
    PERMISSIONS.USE_QUICK_ACTION_DELIVERABLE,
    PERMISSIONS.EXPORT_DELIVERABLE,
    PERMISSIONS.EXPORT_GR_DELIVERABLE,
    PERMISSIONS.CREATE_DELIVERABLE_SHEET,
    PERMISSIONS.VALIDATE_DELIVERABLE,
    PERMISSIONS.MANAGER_ACCEPT_DELIVERABLE,
    PERMISSIONS.MANAGER_RATE_DELIVERABLE,
    PERMISSIONS.SUBMIT_DELIVERABLE,
    PERMISSIONS.CANCEL_DELIVERABLE,
    PERMISSIONS.CANCEL_ACCEPTANCE_DELIVERABLE,
    PERMISSIONS.CANCEL_SUBMISSION_DELIVERABLE,
    PERMISSIONS.CANCEL_VALIDATION_DELIVERABLE,
    PERMISSIONS.CANCEL_RATING_DELIVERABLE,
    PERMISSIONS.CANCEL_COMMENT_DELIVERABLE,
    PERMISSIONS.EDIT_DELIVERABLE,
    PERMISSIONS.DELETE_DELIVERABLE,
    PERMISSIONS.RESTORE_DELIVERABLE,
    PERMISSIONS.ARCHIVE_ORDER_MANAGEMENT,
    PERMISSIONS.RESTORE_ORDER_MANAGEMENT,
    PERMISSIONS.ARCHIVE_ORDER_QUOTE,
    PERMISSIONS.RESTORE_ORDER_QUOTE,
    PERMISSIONS.CREATE_REPORT,
    PERMISSIONS.CREATE_ORDER,
    PERMISSIONS.EDIT_ORDER,
    PERMISSIONS.CREATE_WORKUNIT_ORDER,
    PERMISSIONS.SHOW_SUMMARY_ORDER,
    PERMISSIONS.AFFECTATION_ORDER,
    PERMISSIONS.DUPLICATE_ORDER,
    PERMISSIONS.DOWNLOAD_ORDER,
    PERMISSIONS.CLOSE_ORDER,
    PERMISSIONS.OPEN_ORDER,
    PERMISSIONS.SHOW_STAT_ORDER,
    PERMISSIONS.SHOW_GRAPH_ORDER,
    PERMISSIONS.SHOW_KPI_ORDER,
    PERMISSIONS.SHOW_TABS_ORDER,
  ],
  bm: [
    PERMISSIONS.SEE_PRICE_DASHBOARD,
    PERMISSIONS.FETCH_ALL_DEVISE_FOR_CATALOG,
    PERMISSIONS.EDIT_CLIENT,
    PERMISSIONS.ADD_DELIVERABLE,
    PERMISSIONS.USE_QUICK_ACTION_DELIVERABLE,
    PERMISSIONS.EXPORT_DELIVERABLE,
    PERMISSIONS.EXPORT_GR_DELIVERABLE,
    PERMISSIONS.CREATE_DELIVERABLE_SHEET,
    PERMISSIONS.DUPLICATE_DELIVERABLE,
    PERMISSIONS.VALIDATE_DELIVERABLE,
    PERMISSIONS.MANAGER_ACCEPT_DELIVERABLE,
    PERMISSIONS.MANAGER_RATE_DELIVERABLE,
    PERMISSIONS.SUBMIT_DELIVERABLE,
    PERMISSIONS.CANCEL_DELIVERABLE,
    PERMISSIONS.CANCEL_SUBMISSION_DELIVERABLE,
    PERMISSIONS.CANCEL_VALIDATION_DELIVERABLE,
    PERMISSIONS.EDIT_DELIVERABLE,
    PERMISSIONS.DELETE_DELIVERABLE,
    PERMISSIONS.RESTORE_DELIVERABLE,
    PERMISSIONS.ARCHIVE_ORDER_QUOTE,
    PERMISSIONS.RESTORE_ORDER_QUOTE,
    PERMISSIONS.CREATE_REPORT,
    PERMISSIONS.CREATE_ORDER,
    PERMISSIONS.EDIT_ORDER,
    PERMISSIONS.CREATE_WORKUNIT_ORDER,
    PERMISSIONS.SHOW_SUMMARY_ORDER,
    PERMISSIONS.AFFECTATION_ORDER,
    PERMISSIONS.DUPLICATE_ORDER,
    PERMISSIONS.DOWNLOAD_ORDER,
    PERMISSIONS.CLOSE_ORDER,
    PERMISSIONS.OPEN_ORDER,
    PERMISSIONS.SHOW_STAT_ORDER,
    PERMISSIONS.SHOW_GRAPH_ORDER,
    PERMISSIONS.SHOW_KPI_ORDER,
    PERMISSIONS.SHOW_TABS_ORDER,
  ],
  butl: [
    PERMISSIONS.SEE_PRICE_DASHBOARD,
    PERMISSIONS.CREATE_CATALOG,
    PERMISSIONS.USE_QUICK_ACTION_DELIVERABLE,
    PERMISSIONS.EXPORT_DELIVERABLE,
    PERMISSIONS.EXPORT_GR_DELIVERABLE,
    PERMISSIONS.SHOW_SUMMARY_ORDER,
    PERMISSIONS.AFFECTATION_ORDER,
    PERMISSIONS.DUPLICATE_ORDER,
    PERMISSIONS.CREATE_REPORT,
    PERMISSIONS.DOWNLOAD_ORDER,
    PERMISSIONS.SHOW_STAT_ORDER,
    PERMISSIONS.SHOW_GRAPH_ORDER,
    PERMISSIONS.SHOW_KPI_ORDER,
    PERMISSIONS.SHOW_TABS_ORDER,
    PERMISSIONS.MANAGER_ACCEPT_DELIVERABLE,
    PERMISSIONS.MANAGER_RATE_DELIVERABLE,
  ],
  consultant: [
    PERMISSIONS.ADD_DELIVERABLE,
    PERMISSIONS.USE_QUICK_ACTION_DELIVERABLE,
    PERMISSIONS.EXPORT_DELIVERABLE,
    PERMISSIONS.DUPLICATE_DELIVERABLE,
    PERMISSIONS.SUBMIT_DELIVERABLE,
    PERMISSIONS.CANCEL_DELIVERABLE,
    PERMISSIONS.CANCEL_SUBMISSION_DELIVERABLE,
    PERMISSIONS.EDIT_DELIVERABLE,
    PERMISSIONS.DELETE_DELIVERABLE,
    PERMISSIONS.RESTORE_DELIVERABLE,
    PERMISSIONS.SHOW_TABS_ORDER,
  ],
  customer: [
    PERMISSIONS.USE_QUICK_ACTION_DELIVERABLE,
    PERMISSIONS.ACCEPT_DELIVERABLE,
    PERMISSIONS.RATE_DELIVERABLE,
    PERMISSIONS.REVIEW_DELIVERABLE,
    PERMISSIONS.SHOW_STAT_ORDER,
  ],
  support_bu: [
    PERMISSIONS.SEE_PRICE_DASHBOARD,
    PERMISSIONS.CREATE_CATALOG,
    PERMISSIONS.ADD_DELIVERABLE,
    PERMISSIONS.DUPLICATE_DELIVERABLE,
    PERMISSIONS.EXPORT_DELIVERABLE,
    PERMISSIONS.EXPORT_GR_DELIVERABLE,
    PERMISSIONS.CREATE_DELIVERABLE_SHEET,
    PERMISSIONS.CANCEL_SUBMISSION_DELIVERABLE,
    PERMISSIONS.RESTORE_DELIVERABLE,
    PERMISSIONS.EDIT_ORDER,
    PERMISSIONS.CREATE_WORKUNIT_ORDER,
    PERMISSIONS.SHOW_SUMMARY_ORDER,
    PERMISSIONS.AFFECTATION_ORDER,
    PERMISSIONS.DUPLICATE_ORDER,
    PERMISSIONS.DOWNLOAD_ORDER,
    PERMISSIONS.SHOW_STAT_ORDER,
    PERMISSIONS.SHOW_KPI_ORDER,
    PERMISSIONS.SHOW_TABS_ORDER,
  ],
  team_leader: [
    PERMISSIONS.SEE_PRICE_DASHBOARD,
    PERMISSIONS.ADD_DELIVERABLE,
    PERMISSIONS.DUPLICATE_DELIVERABLE,
    PERMISSIONS.USE_QUICK_ACTION_DELIVERABLE,
    PERMISSIONS.EXPORT_DELIVERABLE,
    PERMISSIONS.EXPORT_GR_DELIVERABLE,
    PERMISSIONS.CREATE_DELIVERABLE_SHEET,
    PERMISSIONS.VALIDATE_DELIVERABLE,
    PERMISSIONS.MANAGER_ACCEPT_DELIVERABLE,
    PERMISSIONS.MANAGER_RATE_DELIVERABLE,
    PERMISSIONS.SUBMIT_DELIVERABLE,
    PERMISSIONS.CANCEL_DELIVERABLE,
    PERMISSIONS.CANCEL_SUBMISSION_DELIVERABLE,
    PERMISSIONS.CANCEL_VALIDATION_DELIVERABLE,
    PERMISSIONS.EDIT_DELIVERABLE,
    PERMISSIONS.DELETE_DELIVERABLE,
    PERMISSIONS.RESTORE_DELIVERABLE,
    PERMISSIONS.CREATE_REPORT,
    PERMISSIONS.EDIT_ORDER,
    PERMISSIONS.CREATE_WORKUNIT_ORDER,
    PERMISSIONS.SHOW_SUMMARY_ORDER,
    PERMISSIONS.AFFECTATION_ORDER,
    PERMISSIONS.DOWNLOAD_ORDER,
    PERMISSIONS.CLOSE_ORDER,
    PERMISSIONS.OPEN_ORDER,
    PERMISSIONS.SHOW_STAT_ORDER,
    PERMISSIONS.SHOW_GRAPH_ORDER,
    PERMISSIONS.SHOW_KPI_ORDER,
    PERMISSIONS.SHOW_TABS_ORDER,
  ],
  team_leader_plus: [
    PERMISSIONS.SEE_PRICE_DASHBOARD,
    PERMISSIONS.FETCH_ALL_DEVISE_FOR_CATALOG,
    PERMISSIONS.EDIT_CLIENT,
    PERMISSIONS.ADD_DELIVERABLE,
    PERMISSIONS.DUPLICATE_DELIVERABLE,
    PERMISSIONS.USE_QUICK_ACTION_DELIVERABLE,
    PERMISSIONS.EXPORT_DELIVERABLE,
    PERMISSIONS.EXPORT_GR_DELIVERABLE,
    PERMISSIONS.CREATE_DELIVERABLE_SHEET,
    PERMISSIONS.VALIDATE_DELIVERABLE,
    PERMISSIONS.MANAGER_ACCEPT_DELIVERABLE,
    PERMISSIONS.MANAGER_RATE_DELIVERABLE,
    PERMISSIONS.SUBMIT_DELIVERABLE,
    PERMISSIONS.CANCEL_DELIVERABLE,
    PERMISSIONS.CANCEL_SUBMISSION_DELIVERABLE,
    PERMISSIONS.CANCEL_VALIDATION_DELIVERABLE,
    PERMISSIONS.EDIT_DELIVERABLE,
    PERMISSIONS.DELETE_DELIVERABLE,
    PERMISSIONS.RESTORE_DELIVERABLE,
    PERMISSIONS.CREATE_REPORT,
    PERMISSIONS.ARCHIVE_ORDER_QUOTE,
    PERMISSIONS.RESTORE_ORDER_QUOTE,
    PERMISSIONS.CREATE_ORDER,
    PERMISSIONS.EDIT_ORDER,
    PERMISSIONS.CREATE_WORKUNIT_ORDER,
    PERMISSIONS.SHOW_SUMMARY_ORDER,
    PERMISSIONS.AFFECTATION_ORDER,
    PERMISSIONS.DUPLICATE_ORDER,
    PERMISSIONS.DOWNLOAD_ORDER,
    PERMISSIONS.CLOSE_ORDER,
    PERMISSIONS.OPEN_ORDER,
    PERMISSIONS.SHOW_STAT_ORDER,
    PERMISSIONS.SHOW_GRAPH_ORDER,
    PERMISSIONS.SHOW_KPI_ORDER,
    PERMISSIONS.SHOW_TABS_ORDER,
  ],
};
