import OrderWorkunit from 'types/entities/OrderWorkunit';
import { MissionAdvancementPossibilities } from 'types/enums/MissionAdvancementPossibilities';
import { OrderWorkunitOrigin } from 'types/enums/OrderWorkunitOrigin';
import { OrderWorkunitsLabel } from 'types/enums/OrderWorkunitsLabel';
import { DeliverableActions } from '../actions/deliverableActions';
import { RootActions } from '../redux';
import { DeliverableState } from '../states/deliverableState';

export const cancelDeliverable = (state: DeliverableState, action: RootActions): DeliverableState => {
  if (action.type !== DeliverableActions.CANCEL_DELIVERABLE) {
    return state;
  }

  const cancel = (deliverable: OrderWorkunit) => {
    if (!action.payload.ids.includes(deliverable.id)) {
      return deliverable;
    }
    const separator = '|';
    const labels = deliverable.label?.split(separator) || [];
    if (!labels.includes(OrderWorkunitsLabel.CANCELLED.toLowerCase())) {
      labels.push(OrderWorkunitsLabel.CANCELLED.toLowerCase());
    }
    return {
      ...deliverable,
      label: labels.join(separator),
      cancellation_reason: action.payload.cancellationReason,
      mission_advancement_id: null,
    };
  };

  return {
    ...state,
    deliverables: state?.deliverables?.map(cancel) ?? [],
  };
};

export const restoreDeliverable = (state: DeliverableState, action: RootActions): DeliverableState => {
  if (action.type !== DeliverableActions.RESTORE_DELIVERABLE) {
    return state;
  }

  const restore = (deliverable: OrderWorkunit) => {
    if (deliverable.id !== action.payload) {
      return deliverable;
    }

    const separator = '|';
    const labels = deliverable.label?.split(separator) || [];
    const cancelledIndex = labels.indexOf(OrderWorkunitsLabel.CANCELLED.toLowerCase());
    if (cancelledIndex !== -1) {
      labels.splice(cancelledIndex, 1);
    }

    return {
      ...deliverable,
      label: labels.join(separator),
      cancellation_reason: null,
    };
  };

  return {
    ...state,
    deliverables: state?.deliverables?.map(restore) ?? [],
  };
};

export const changeMissionAdvancement = (state: DeliverableState, action: RootActions): DeliverableState => {
  if (action.type !== DeliverableActions.CHANGE_MISSION_ADVANCEMENT) {
    return state;
  }

  const change = (deliverable: OrderWorkunit) =>
    !action.payload.ids.includes(deliverable.id)
      ? deliverable
      : {
          ...deliverable,
          mission_advancement_id: action.payload.mission_advancement_id,
          rating: action.payload.mission_advancement_id === MissionAdvancementPossibilities.REFUSED_CLIENT ? 'E' : '',
          validator: action.payload.validator,
        };

  return {
    ...state,
    deliverables: state.deliverables?.map(change) ?? [],
  };
};

export const changeRating = (state: DeliverableState, action: RootActions): DeliverableState => {
  if (action.type !== DeliverableActions.CHANGE_RATING) {
    return state;
  }

  const change = (deliverable: OrderWorkunit) =>
    !action.payload.ids.includes(deliverable.id)
      ? deliverable
      : {
          ...deliverable,
          reviewer: action.payload.reviewer,
          rating: action.payload.rating,
          'mission-comments': action.payload.comment
            ? [...deliverable['mission-comments'], action.payload.comment]
            : deliverable['mission-comments'],
        };

  return {
    ...state,
    deliverables: state.deliverables?.map(change) ?? [],
  };
};

export const resetRating = (state: DeliverableState, action: RootActions): DeliverableState => {
  if (action.type !== DeliverableActions.RESET_RATING) {
    return state;
  }

  const change = (deliverable: OrderWorkunit) =>
    action.payload !== deliverable.id
      ? deliverable
      : {
          ...deliverable,
          rating: null,
        };

  return {
    ...state,
    deliverables: state.deliverables?.map(change) ?? [],
  };
};

export const addCommentDeliverable = (state: DeliverableState, action: RootActions): DeliverableState => {
  if (action.type !== DeliverableActions.ADD_COMMENT_DELIVERABLE) {
    return state;
  }

  const change = (deliverable: OrderWorkunit) =>
    deliverable.id !== action.payload.id
      ? deliverable
      : {
          ...deliverable,
          'mission-comments': action.payload.comment
            ? [...deliverable['mission-comments'], action.payload.comment]
            : deliverable['mission-comments'],
        };

  return {
    ...state,
    deliverables: state.deliverables?.map(change) ?? [],
  };
};

export const updateCommentDeliverable = (state: DeliverableState, action: RootActions): DeliverableState => {
  if (action.type !== DeliverableActions.UPDATE_COMMENT_DELIVERABLE) {
    return state;
  }

  const change = (deliverable: OrderWorkunit) =>
    deliverable.id !== action.payload.deliverableId
      ? deliverable
      : {
          ...deliverable,
          'mission-comments': deliverable['mission-comments'].map((commentary) =>
            commentary.id !== action.payload.id ? commentary : action.payload.comment
          ),
        };

  return {
    ...state,
    deliverables: state.deliverables?.map(change) ?? [],
  };
};

export const deleteCommentDeliverable = (state: DeliverableState, action: RootActions): DeliverableState => {
  if (action.type !== DeliverableActions.DELETE_COMMENT_DELIVERABLE) {
    return state;
  }

  const change = (deliverable: OrderWorkunit) =>
    deliverable.id !== action.payload.deliverableId
      ? deliverable
      : {
          ...deliverable,
          'mission-comments': deliverable['mission-comments'].filter(
            (commentary) => !action.payload.ids.includes(commentary.id)
          ),
        };

  return {
    ...state,
    deliverables: state.deliverables?.map(change) ?? [],
  };
};

export const editDeliverables = (state: DeliverableState, action: RootActions): DeliverableState => {
  if (action.type !== DeliverableActions.EDIT_DELIVERABLES) {
    return state;
  }
  type KeyOfOrderWorkunits = keyof OrderWorkunit;

  const updateBulkDeliverables = (
    orderWorkunits: OrderWorkunit[],
    orderWorkunitsIds: OrderWorkunit['id'][],
    changes: Partial<OrderWorkunit>
  ) => {
    const key_forecast_date: KeyOfOrderWorkunits = 'forecast_date';
    const key_is_archived: KeyOfOrderWorkunits = 'is_archived';
    if (Object.keys(changes).includes(key_is_archived)) {
      return orderWorkunits.filter((owu) => !orderWorkunitsIds.includes(owu.id));
    }

    const handleUpdateChangeUser = (
      changes: Partial<OrderWorkunit>,
      orderWorkunit: OrderWorkunit,
      target: 'client' | 'consultant'
    ) => {
      if (changes[target] && changes[target] !== null) {
        return `${(changes[target] as any).last_name.toUpperCase()} ${(changes[target] as any).first_name}`;
      }
      if (changes[target] === null) {
        return '';
      }
      return target === 'client' ? orderWorkunit.client_fullname : orderWorkunit.consultant_fullname;
    };

    return orderWorkunits.map((owu) => {
      const indexDeliverable = orderWorkunitsIds.findIndex((orderWorkunitId) => orderWorkunitId === owu.id);
      if (indexDeliverable === -1) return owu;
      if (Object.keys(changes).includes(key_forecast_date) && owu.origin === OrderWorkunitOrigin.QUOTE) {
        return {
          ...owu,
          ...changes,
          consultant_fullname: handleUpdateChangeUser(changes, owu, 'consultant'),
          client_fullname: handleUpdateChangeUser(changes, owu, 'client'),
          label: owu?.label?.length ? owu.label?.concat('|updated') : 'updated',
        };
      }
      return {
        ...owu,
        ...changes,
        consultant_fullname: handleUpdateChangeUser(changes, owu, 'consultant'),
        client_fullname: handleUpdateChangeUser(changes, owu, 'client'),
      };
    });
  };

  return {
    ...state,
    deliverables: updateBulkDeliverables(
      state.deliverables ?? [],
      action.payload.orderWorkunitIds,
      action.payload.changes
    ),
  };
};
